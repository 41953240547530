import { useEffect, useState } from "react";
import { calcDate } from "../../Common/utils";
import { useUserContext } from "../../Context/UserContext";

const Card = (props: CardProps) => {
  const { timeDiff } = useUserContext();
  const [rewardAvailable, setRewardAvailable] = useState(false);
  useEffect(() => {
    props.lastClaim >= props.stakedAt
      ? Math.floor(
          (new Date().getTime() / 1000 + timeDiff - props.stakedAt) /
            props.CLAIM_DATE
        ) -
          Math.floor((props.lastClaim - props.stakedAt) / props.CLAIM_DATE) <
        1
        ? setRewardAvailable(false)
        : setRewardAvailable(true)
      : props.stakedAt + props.CLAIM_DATE >
        new Date().getTime() / 1000 + timeDiff
      ? setRewardAvailable(false)
      : setRewardAvailable(true);
  }, [props.lastClaim, props.stakedAt, props.CLAIM_DATE, timeDiff]);
  return (
    <div
      className={`rounded-lg bg-[#0a1a1b] w-full ${
        props.selected && "border-4 border-[#3A9FF2]"
      }`}
    >
      <div
        className="relative w-full aspect-square"
        onClick={() => {
          props.staked
            ? props.onUnStakeClick(props.id, rewardAvailable)
            : props.onStakeClick(props.id);
        }}
      >
        <img
          src={props.imgUrl}
          className={`${
            props.staked ? "rounded-t-lg " : "rounded-lg"
          } w-full h-full object-cover`}
          alt="Card"
        />
        <div className="flex justify-between w-full absolute px-2 py-1 bottom-0">
          <p className="font-BubblegumSans text-[white] text-[14px]">
            {props.staked ? calcDate(props.nextClaim) : ""}
          </p>
          <p className="font-BubblegumSans text-[white] text-[14px]">
            #{props.id}
          </p>
        </div>
      </div>

      {props.staked && (
        <div className="relative p-2">
          <p className="font-BubblegumSans mb-1 text-start text-xl text-white">
            <span className="text-[#5c9e90] text-2xl">{props.artifact}</span>{" "}
            Artifacts
          </p>
          <div
            className={`text-xl font-[400] transition-all font-BubblegumSans border-2 py-1 rounded-lg w-full tracking-[1px] uppercase text-center ${
              rewardAvailable
                ? "text-white border-white"
                : "text-gray-700 border-gray-700"
            }`}
          >
            {rewardAvailable ? "Reward Possible" : "Waiting Reward"}
          </div>
        </div>
      )}
    </div>
  );
};

export interface CardProps {
  imgUrl: string;
  staked: boolean;
  artifact: number;
  stakedAt: number;
  nextClaim: number;
  lastClaim: number;
  CLAIM_DATE: number;
  id: number;
  selected: boolean;
  onClaimClick?: (nft: number) => void;
  onStakeClick: (nft: number) => void;
  onUnStakeClick: (nft: number, rewardPossible: boolean) => void;
}

export default Card;
