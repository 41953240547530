export const remainDate = (
  stakedAt: number,
  nowTime: number,
  claimDate: number
) => {
  let tempDate = stakedAt;
  while (tempDate < nowTime) {
    tempDate = tempDate + claimDate;
  }
  return tempDate - nowTime;
};

export const calcDate = (date: number) => {
  const sec = Math.trunc(date);
  return `${Math.trunc(sec / (3600 * 24))} Day ${Math.trunc(
    (sec % (3600 * 24)) / 3600
  )}h ${Math.trunc((sec % 3600) / 60)}min ${Math.trunc(sec % 60)}s`;
};
