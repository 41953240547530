import { Routes, Route, Navigate } from "react-router-dom";

import Dashboard from "./Pages/Dashboard";
import MasterMind from "./Pages/MasterMind";
import Staking from "./Pages/Staking";
import Treasure from "./Pages/Treasure";

import { useUserContext } from "./Context/UserContext";

function RoutesComp() {
  const { walletAddress } = useUserContext();

  return (
    <>
      <Routes>
        {walletAddress === "" ? (
          <>
            <Route path="/" element={<Dashboard />}></Route>
            <Route path="*" element={<Navigate replace to="/" />} />
          </>
        ) : (
          <>
            <Route path="/" element={<Dashboard />}></Route>
            <Route path="/mastermind" element={<MasterMind />}></Route>
            <Route path="/staking" element={<Staking />}></Route>
            <Route path="/treasure" element={<Treasure />}></Route>
            <Route path="*" element={<Navigate replace to="/" />} />
          </>
        )}
      </Routes>
    </>
  );
}

export default RoutesComp;
