import { useEffect, useState } from "react";

import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";

import {
  UNKNOWN_IMG,
  COLLECTION_SECOND,
  ADMIN_WALLET,
} from "../../Common/config";
import { getCollections } from "../../Hooks/BackendHook";
import { ICollectionsType } from "../../PropsType";
import { getNFT } from "../../Hooks/Hook";

import SellCard from "../../Components/SellCard";
import Spin from "../../Components/Spin";
import ArtifactTreasureModal from "../../Components/ArtifactTreasureModal";

const Treasure = () => {
  const [loading, setLoading] = useState(false);
  const [accordionOpen, setAccordionOpen] = useState(1);
  const [clickCardNumber, setClickCardNumber] = useState(0);
  const [clickTabNumber, setClickTabNumber] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [collections, setCollections] = useState<ICollectionsType[]>([]);

  const handleOpenModal = () => setOpenModal(!openModal);

  const initData = async () => {
    setLoading(true);
    setCollections([]);
    const collectionsRes: any = await getCollections();
    const collectionItem: ICollectionsType[] = [];
    for (const item of collectionsRes) {
      let fetchNFTs = await getNFT(ADMIN_WALLET, item.collectionAddress);
      const nftURL = [];
      const nftPrice = [];
      const nftNumber = [];
      for (const nft of fetchNFTs) {
        if (nft.attributes !== "legend") {
          nftURL.push(nft.image);
          if (item.collectionAddress === COLLECTION_SECOND) {
            console.log(nft.attributes);
            if (nft.attributes === "male") nftPrice.push(50);
            else if (nft.attributes === "female") nftPrice.push(60);
            else if (nft.attributes === "astral") nftPrice.push(80);
            else if (nft.attributes === "undead") nftPrice.push(70);
          } else nftPrice.push(item.price);
          nftNumber.push(nft.id);
        }
      }
      const newCollection = {
        ID: item._id,
        ABI: item.collectionABI,
        CollectionAddress: item.collectionAddress,
        CollectionName: item.collectionName,
        CollectionIMG: nftURL,
        CollectionNumber: nftNumber,
        CollectionPrice: nftPrice,
      };
      collectionItem.push(newCollection);
    }
    console.log(collectionItem);
    setCollections(collectionItem);
    setLoading(false);
  };

  useEffect(() => {
    initData();
  }, []);

  return (
    <div className="flex flex-col lg:pb-5 items-center text-center background-color min-h-[100vh]">
      {loading && <Spin />}
      <div className="px-4 md:container mx-auto mt-[150px]">
        <div className="mt-[50px]">
          {collections &&
            collections.map((collection, _index) => {
              return (
                <Accordion
                  key={_index + 1}
                  open={accordionOpen === _index + 1}
                  className="border border-blue-gray-100 px-4 rounded-lg mb-2"
                >
                  <AccordionHeader
                    onClick={() => {
                      setAccordionOpen(_index + 1);
                      setClickTabNumber(_index);
                    }}
                    className={`border-b-0 text-white hover:text-white font-BubblegumSans tracking-[2px] ${
                      accordionOpen === _index + 1 ? "text-[#5c9e90]" : ""
                    }`}
                  >
                    {collection.CollectionName}
                  </AccordionHeader>
                  <AccordionBody className="text-xl text-white text-start font-BubblegumSans font-normal pt-0 tracking-[1px]">
                    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 lg:gap-4 2xl:grid-cols-5 3xl:grid-cols-7 4xl:grid-cols-8 5xl:grid-cols-9 max-h-[250px] md:max-h-[350px] lg:max-h-[450px] overflow-auto">
                      {collection.CollectionIMG.map((item, _index1) => {
                        return (
                          <SellCard
                            img={item}
                            price={collection.CollectionPrice[_index1]}
                            id={collection.CollectionNumber[_index1]}
                            key={_index1}
                            onCardClick={() => {
                              setClickCardNumber(_index1);
                              handleOpenModal();
                            }}
                          />
                        );
                      })}
                    </div>
                  </AccordionBody>
                </Accordion>
              );
            })}
        </div>
      </div>
      <ArtifactTreasureModal
        img={
          collections.length !== 0
            ? collections[clickTabNumber].CollectionIMG[clickCardNumber]
            : UNKNOWN_IMG
        }
        price={
          collections.length !== 0
            ? collections[clickTabNumber].CollectionPrice[clickCardNumber]
            : -1
        }
        id={
          collections.length !== 0
            ? collections[clickTabNumber].CollectionNumber[clickCardNumber]
            : 0
        }
        collection={
          collections.length !== 0
            ? collections[clickTabNumber].CollectionAddress
            : ""
        }
        collectionName={
          collections.length !== 0
            ? collections[clickTabNumber].CollectionName
            : ""
        }
        open={openModal}
        onHandleOpen={handleOpenModal}
        onInitNFT={initData}
      />
    </div>
  );
};

export default Treasure;
