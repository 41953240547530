import { useState } from "react";
// import Carousel from "react-spring-3d-carousel";
// import { config } from "react-spring";
import Slider from "react-slick";

import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import { useContract, useSigner } from "wagmi";

// import SlideCard from "../../Components/SlideCard";
import CollectionsSlider from "../../Components/CollectionsSlider";
import Button from "../../Components/Button";
import NFTInfoModal from "../../Components/NFTInfoModal";

import { useUserContext } from "../../Context/UserContext";

import { COLLECTION_SECOND, DIGI2_ABI } from "../../Common/config";

import { toaster } from "../../Components/Toast";
import { mintGenNFT } from "../../Hooks/Hook";

import LogoExpedition from "../../Assets/img/Logo-ExpeditionLabs.jpg";
import LogoFusionXYZ from "../../Assets/img/Logo-FusionXYZ.jpg";
import LogoInpaekLogo from "../../Assets/img/Logo-INPEAKLOGO.jpg";
import LogoMOON from "../../Assets/img/Logo-MOON.jpg";
import LogoOrder from "../../Assets/img/Logo-OrderOfTheEgonauts.jpg";
import LogoMeo from "../../Assets/img/Logo-TheMeo.jpg";
import LogoKeungz from "../../Assets/img/keungz-logo.jpg";
import LogoYGPZ from "../../Assets/img/YGPZ-logo2x.jpg";
import Xplorius from "../../Assets/img/Xplorius.svg";
import DashboardVideo from "../../Assets/img/DREAM.mov";
import BabeChez from "../../Assets/img/babe.png";
import Bandini from "../../Assets/img/Bandini.png";
import BDogg from "../../Assets/img/BDogg.png";
import Fahadarif from "../../Assets/img/Fahadari.png";
import Pamchito from "../../Assets/img/Pamchito.png";
import MommyTokyo from "../../Assets/img/MommyTokyo.png";
import Loft from "../../Assets/img/Loft.png";
import Keungz from "../../Assets/img/Keungz.png";
import MetaAngel from "../../Assets/img/MetaAngel.png";
import Abracadbra from "../../Assets/img/Abracadabra.png";
import Venture from "../../Assets/img/Venture.png";

const Dashboard = () => {
  // let xDown: any = null;
  // let yDown: any = null;
  let memberSettings = {
    className: "slider variable-width",
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  const teamMembersName = [
    "BebeChez - Founder",
    "Keungz - Dev/Advisor",
    "Bandini - Art & Brand Director",
    "Abracadabra - Lead Developer",
    "BDogg -  Collab Manager",
    "Fahadarif.eth -  Collab Manager",
    "Pamchito -  Community Manager",
    "MommyTokyo - Events Manager",
    "Loft - Discord Admin",
    "MetaAngel - Security/Regulations Researcher",
    "Venture - Full Stack/Smart Contract Dev",
  ];

  const teamMembersImage = [
    BabeChez,
    Keungz,
    Bandini,
    Abracadbra,
    BDogg,
    Fahadarif,
    Pamchito,
    MommyTokyo,
    Loft,
    MetaAngel,
    Venture,
  ];

  const { walletAddress } = useUserContext();
  const { data: signerData } = useSigner();

  // const [state, setState] = useState({
  //   goToSlide: 0,
  //   offsetRadius: 2,
  //   showNavigation: false,
  //   config: config.gentle,
  // });
  const nft2Contract = useContract({
    address: COLLECTION_SECOND,
    abi: DIGI2_ABI,
    signerOrProvider: signerData,
  });
  const [open, setOpen] = useState(1);

  // const [selectedMember, setSelectedMember] = useState(0);
  const [mintNFTs, setMintNFTs] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  // const slides = [
  //   {
  //     key: 1,
  //     content: (
  //       <SlideCard
  //         url="https://i.seadn.io/gcs/files/2acca9cfdc38ce283acdf8c9a07a1e96.png?auto=format&dpr=1&w=384"
  //         id={123}
  //       />
  //     ),
  //   },
  //   {
  //     key: 2,
  //     content: (
  //       <SlideCard
  //         url="https://i.seadn.io/gcs/files/2acca9cfdc38ce283acdf8c9a07a1e96.png?auto=format&dpr=1&w=384"
  //         id={234}
  //       />
  //     ),
  //   },
  //   {
  //     key: 3,
  //     content: (
  //       <SlideCard
  //         url="https://i.seadn.io/gcs/files/2acca9cfdc38ce283acdf8c9a07a1e96.png?auto=format&dpr=1&w=384"
  //         id={345}
  //       />
  //     ),
  //   },
  //   {
  //     key: 4,
  //     content: (
  //       <SlideCard
  //         url="https://i.seadn.io/gcs/files/2acca9cfdc38ce283acdf8c9a07a1e96.png?auto=format&dpr=1&w=384"
  //         id={465}
  //       />
  //     ),
  //   },
  //   {
  //     key: 5,
  //     content: (
  //       <SlideCard
  //         url="https://i.seadn.io/gcs/files/2acca9cfdc38ce283acdf8c9a07a1e96.png?auto=format&dpr=1&w=384"
  //         id={245}
  //       />
  //     ),
  //   },
  //   {
  //     key: 6,
  //     content: (
  //       <SlideCard
  //         url="https://i.seadn.io/gcs/files/2acca9cfdc38ce283acdf8c9a07a1e96.png?auto=format&dpr=1&w=384"
  //         id={164}
  //       />
  //     ),
  //   },
  //   {
  //     key: 7,
  //     content: (
  //       <SlideCard
  //         url="https://i.seadn.io/gcs/files/2acca9cfdc38ce283acdf8c9a07a1e96.png?auto=format&dpr=1&w=384"
  //         id={876}
  //       />
  //     ),
  //   },
  //   {
  //     key: 8,
  //     content: (
  //       <SlideCard
  //         url="https://i.seadn.io/gcs/files/2acca9cfdc38ce283acdf8c9a07a1e96.png?auto=format&dpr=1&w=384"
  //         id={678}
  //       />
  //     ),
  //   },
  // ].map((slide, index) => {
  //   return {
  //     ...slide,
  //     onClick: () => setState({ ...state, goToSlide: index }),
  //   };
  // });

  // const getTouches = (evt: any) => {
  //   return (
  //     evt.touches || evt.originalEvent.touches // browser API
  //   ); // jQuery
  // };

  // const handleTouchStart = (evt: any) => {
  //   const firstTouch = getTouches(evt)[0];
  //   xDown = firstTouch.clientX;
  //   yDown = firstTouch.clientY;
  // };

  // const handleTouchMove = (evt: any) => {
  //   if (!xDown || !yDown) {
  //     return;
  //   }

  //   let xUp = evt.touches[0].clientX;
  //   let yUp = evt.touches[0].clientY;

  //   let xDiff = xDown - xUp;
  //   let yDiff = yDown - yUp;

  //   if (Math.abs(xDiff) > Math.abs(yDiff)) {
  //     /*most significant*/
  //     if (xDiff > 0) {
  //       /* left swipe */
  //       setState({ ...state, goToSlide: state.goToSlide + 1 });
  //     } else {
  //       /* right swipe */
  //       setState({ ...state, goToSlide: state.goToSlide - 1 });
  //     }
  //   } else {
  //     if (yDiff > 0) {
  //       /* up swipe */
  //     } else {
  //       /* down swipe */
  //     }
  //   }
  //   /* reset values */
  //   xDown = null;
  //   yDown = null;
  // };

  const handleOpen = (value: number) => {
    setOpen(open === value ? 0 : value);
  };

  const handleOpenModal = () => setOpenModal(!openModal);

  const mintNFT = async () => {
    let res = await mintGenNFT(nft2Contract, mintNFTs);
    if (res) toaster("success", "Mint Success!");
  };

  return (
    <div>
      <div className="header__background-image h-[100vh] flex justify-center items-end">
        <video className="w-[80%] md:w-[60%] lg:w-[50%] mb-[70px]" controls>
          <source src={DashboardVideo} type="video/mp4" />
        </video>
      </div>
      <div className="flex flex-col lg:pb-5 justify-between background-color ">
        <div className="pt-[50px] md:container mx-auto">
          <Button text="Artifact Checker" onClick={handleOpenModal} />
        </div>
        <div className="px-4 md:container mx-auto">
          <section className="mb-[76px] md:mb-[116px]" id="about"></section>
          <section className="text-center">
            <p className="text-white font-bold text-3xl md:text-5xl tracking-[2px] font-BubblegumSans mt-[10px]">
              About US
            </p>
            <div className="flex justify-center mt-[10px]">
              <div>
                <Accordion
                  open={open === 1}
                  className="border border-blue-gray-100 px-4 rounded-lg mb-2"
                >
                  <AccordionHeader
                    onClick={() => handleOpen(1)}
                    className={`border-b-0 text-gray-600 hover:text-white font-BubblegumSans tracking-[2px] ${
                      open === 1 ? "text-[#5c9e90]" : ""
                    }`}
                  >
                    Who is BebeChez?
                  </AccordionHeader>
                  <AccordionBody className="text-xl text-white text-start font-BubblegumSans font-normal pt-0 tracking-[1px]">
                    Also known as Dr. Brian Ramos, Bebe is a neuroscientist,
                    author, stress specialist, health expert, and yoga master.
                    He is a teacher, not a speaker, with a powerful voice in the
                    mental health field and an advocate of reconnecting with
                    nature to re-establish health and harmony in ourselves and
                    the biosphere. He is currently the founder of DigiMonkz NFT
                    and lead advisor to other Web3 ventures, such as YogaPetz &
                    WAAA.
                    <br />
                    <br /> Bebe has a Ph.D. in Neuroscience from Yale School of
                    Medicine. He is the author of numerous publications in the
                    area of neuroscience and molecular psychiatry with a special
                    interest in stress-related disorders, cognitive enhancement,
                    aging, and behavior. He has developed practices that anyone
                    can learn and integrate into their life to become freer of
                    stress, disease, and F.U.D. He works from within and without
                    to extirpate the weeds that limit your potential for success
                    in any endeavor.
                  </AccordionBody>
                </Accordion>
                <Accordion
                  open={open === 2}
                  className="border border-blue-gray-100 px-4 rounded-lg mb-2"
                >
                  <AccordionHeader
                    onClick={() => handleOpen(2)}
                    className={`border-b-0 text-gray-600 hover:text-white font-BubblegumSans tracking-[2px] ${
                      open === 2 ? "text-[#5c9e90]" : ""
                    }`}
                  >
                    What is Digimonkz?
                  </AccordionHeader>
                  <AccordionBody className="text-xl text-white text-start font-BubblegumSans font-normal pt-0 tracking-[1px]">
                    DigiMonkz is the leading Web3 Wellness NFT collection that
                    is building alongside other passionate and accomplished
                    entrepreneurs as we enter a Digital Age of unprecedented
                    mental health problems. DigiMonkz fill a HUGE NEED as it
                    provides premium content and instruction to LEVEL UP your
                    mind, body, and spirit. We are designed for the NEXT
                    GENERATION of Digital Entrepreneurs and Web3 Fans. The Life
                    of a Degen Journey, as Bebe calls the DigiMonkz Mastermind,
                    is designed to bring more balance and power to the lives of
                    Web3 Degens.
                    <br />
                    <br />
                    We currently have two collections, the OG Genesis 111 with
                    the Highest level of perks and benefits, and the Genesis 2
                    which is the last of the LIFETIME passes with full access to
                    the platform. Both have many utilities that you can see in
                    our Benefits section.
                  </AccordionBody>
                </Accordion>
                <Accordion
                  open={open === 3}
                  className="border border-blue-gray-100 px-4 rounded-lg mb-2"
                >
                  <AccordionHeader
                    onClick={() => handleOpen(3)}
                    className={`border-b-0 text-gray-600 hover:text-white font-BubblegumSans tracking-[2px] ${
                      open === 3 ? "text-[#5c9e90]" : ""
                    }`}
                  >
                    Benefits
                  </AccordionHeader>
                  <AccordionBody className="text-base text-white text-start font-BubblegumSans font-normal pt-0  tracking-[1px]">
                    <span className="text-xl">
                      - Guidance & Coaching from Bebe Chez with both desktop &
                      mobile platforms coming
                    </span>
                    <br />
                    <span className="text-xl">
                      - YogaPetz ecosystem compatibility (TOP $PRANA & $KARMA
                      boosting Partner Collection) with cool DeFi gaming
                      mechanics coupled with Wellness challenges
                    </span>
                    <br />
                    <span className="text-xl">- Weekly support services</span>
                    <br />
                    &nbsp;&nbsp;&nbsp;o Coaching and classes in performance,
                    life, wellness, mental health, etc.
                    <br />
                    <span className="text-xl">
                      - IRL events (1st ever Web3 Retreat)
                    </span>
                    <br />
                    &nbsp;&nbsp;&nbsp;o With a growing list of U.S. (Miami,
                    Minnesota, San Diego) and foreign-based locations (Sri
                    Lanka, Portugal)
                    <br />
                    <span className="text-xl">- Healing NFTs</span>
                    <br />
                    &nbsp;&nbsp;&nbsp;o World’s 1st healing NFT created by us
                    and existing on the ETH blockchain
                    <br />
                    <span className="text-xl">
                      - Limited edition merchandise (COMING SOON)
                    </span>
                    <br />
                    <span className="text-xl">- Future staking reward</span>
                    <br />
                    &nbsp;&nbsp;&nbsp;o Artifacts?
                  </AccordionBody>
                </Accordion>
              </div>
            </div>
          </section>
          <section
            className="mb-[76px] md:mb-[116px]"
            id="collections"
          ></section>
          <section className="text-center">
            <p className="text-white font-bold text-3xl md:text-5xl tracking-[2px] font-BubblegumSans mt-[10px]">
              Collections
            </p>
            <CollectionsSlider />
          </section>
          <section className="mb-[76px] md:mb-[116px]" id="mynft"></section>
          <section className="text-center">
            {walletAddress === "" ? (
              <p className="text-white font-BubblegumSans tracking-[2px] w-full">
                Please Connect your wallet
              </p>
            ) : (
              <div>
                <p className="text-white font-bold text-3xl md:text-5xl tracking-[2px] font-BubblegumSans mt-[10px]">
                  Mint
                </p>
                <div>
                  <div>
                    <input
                      className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mt-[10px] w-[300px] font-BubblegumSans text-center text-2xl"
                      id="mintNumber"
                      type="number"
                      placeholder="Max 756"
                      value={mintNFTs}
                      min={0}
                      onChange={(e) => {
                        setMintNFTs(Number(e.target.value));
                      }}
                    />
                  </div>
                  {mintNFTs !== 0 && (
                    <div className="w-[250px] mx-auto">
                      <Button
                        onClick={mintNFT}
                        text={`Mint (${mintNFTs * 0.26} ETH)`}
                      />
                    </div>
                  )}
                </div>
                {/* <p className="text-white font-bold text-3xl md:text-5xl tracking-[2px] font-BubblegumSans mt-[50px]">
                  Your NFTs
                </p>
                <div className="mt-[30px] flex justify-center">
                  <div className="w-full h-[500px]">
                    <div
                      className="mx-auto h-full"
                      onTouchStart={handleTouchStart}
                      onTouchMove={handleTouchMove}
                    >
                      <Carousel
                        slides={slides}
                        goToSlide={state.goToSlide}
                        offsetRadius={state.offsetRadius}
                        showNavigation={state.showNavigation}
                        animationConfig={state.config}
                      />
                    </div>
                  </div>
                </div> */}
              </div>
            )}
          </section>
          <section className="mb-[76px] md:mb-[116px]" id="partner"></section>
          <section className="text-center">
            <p className="text-white font-bold text-3xl md:text-5xl tracking-[2px] font-BubblegumSans mt-[10px]">
              Collab Partners
            </p>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-4 mt-[50px]">
              <div className="mx-auto shine">
                <img
                  src={LogoYGPZ}
                  className="w-[300px] h-[300px] object-contain"
                  alt="LogoYGPZ"
                />
                {/* <p className="text-4xl font-BubblegumSans mt-[5px] text-white">
                  YogaPetz
                </p> */}
              </div>
              <div className="mx-auto shine">
                <img
                  src={LogoKeungz}
                  className="w-[300px] h-[300px] object-contain"
                  alt="LogoKeungz"
                />
                {/* <p className="text-4xl font-BubblegumSans mt-[5px] text-white">
                  Keungz Genesis
                </p> */}
              </div>
              <div className="mx-auto shine">
                <img
                  src={LogoMOON}
                  className="w-[300px] h-[300px] object-contain"
                  alt="Moon"
                />
                {/* <p className="text-4xl font-BubblegumSans mt-[5px] text-white">
                  MOONzii
                </p> */}
              </div>
              <div className="mx-auto shine">
                <img
                  src={LogoFusionXYZ}
                  className="w-[300px] h-[300px] object-contain"
                  alt="Fusion"
                />
                {/* <p className="text-4xl font-BubblegumSans mt-[5px] text-white">
                  FusionXnft
                </p> */}
              </div>
              <div className="mx-auto shine">
                <img
                  src={Xplorius}
                  className="w-[300px] h-[300px] object-contain"
                  alt="Xplorius"
                />
                {/* <p className="text-4xl font-BubblegumSans mt-[5px] text-white">
                  xPLORIUS
                </p> */}
              </div>
              <div className="mx-auto shine">
                <img
                  src={LogoInpaekLogo}
                  className="w-[300px] h-[300px] object-contain"
                  alt="InPeak"
                />
                {/* <p className="text-4xl font-BubblegumSans mt-[5px] text-white">
                  InPeak
                </p> */}
              </div>
              <div className="mx-auto shine">
                <img
                  src={LogoExpedition}
                  className="w-[300px] h-[300px] object-contain"
                  alt="Expedition"
                />
                {/* <p className="text-4xl font-BubblegumSans mt-[5px] text-white">
                  Expedition Labs
                </p> */}
              </div>
              <div className="mx-auto shine">
                <img
                  src={LogoOrder}
                  className="w-[300px] h-[300px] object-contain"
                  alt="Order"
                />
                {/* <p className="text-4xl font-BubblegumSans mt-[5px] text-white">
                  EgoNautsNFT
                </p> */}
              </div>
              <div className="mx-auto shine">
                <img
                  src={LogoMeo}
                  className="w-[300px] h-[300px] object-contain"
                  alt="Meo"
                />
                {/* <p className="text-4xl font-BubblegumSans mt-[5px] text-white">
                  TheMeoNFT
                </p> */}
              </div>
            </div>
          </section>
          <section className="mb-[76px] md:mb-[116px]" id="team"></section>
          <section className="text-center">
            <p className="text-white font-bold text-3xl md:text-5xl tracking-[2px] font-BubblegumSans mt-[10px]">
              Dream Team
            </p>
            <div className="mt-[50px] lg:flex lg:justify-center lg:items-center hidden md:block">
              {/* <div className="w-full lg:w-[50%]">
                <p className="font-BubblegumSans text-3xl text-white font-bold">
                  {teamMembersName[selectedMember]}
                </p>
                <img
                  src={teamMembersImage[selectedMember]}
                  alt="Selected Person"
                  className="w-[80%] mx-auto mt-[10px] lg:h-[400px] xl:h-[500px] 2xl:h-[600px]"
                />
              </div> */}
              <div className="w-full mb-[50px]">
                <Slider {...memberSettings}>
                  {teamMembersName.map((item, index) => {
                    return (
                      <div
                        key={item}
                        className="mr-[20px] border-0"
                        // onClick={() => {
                        //   setSelectedMember(index);
                        // }}
                      >
                        <img
                          src={teamMembersImage[index]}
                          alt={item}
                          className="w-[300px] h-[300px] object-cover rounded-2xl"
                        />
                        <p className="font-BubblegumSans text-white font-bold">
                          {item}
                        </p>
                      </div>
                    );
                  })}
                </Slider>
              </div>
            </div>
            <div className="block md:hidden">
              <div className="w-full mt-[50px] grid grid-cols-1 sm:grid-cols-2 gap-4">
                {teamMembersName.map((item, index) => {
                  return (
                    <div key={item}>
                      <div className="flex justify-center w-full sm:w-[300px] sm:h-[300px]">
                        <img
                          src={teamMembersImage[index]}
                          alt={item}
                          className="object-cover rounded-2xl w-[300px] sm:w-full"
                        />
                      </div>
                      <p className="font-BubblegumSans text-white font-bold">
                        {item}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>
        </div>
      </div>
      <NFTInfoModal open={openModal} onHandleOpen={handleOpenModal} />
    </div>
  );
};

export default Dashboard;
