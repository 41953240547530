import { FC, ReactElement } from "react";

const SellCard: FC<CardProps> = ({
  img,
  price,
  id,
  onCardClick,
}): ReactElement => {
  return (
    <div
      className="aspect-square relative"
      onClick={() => {
        console.log(id);
        onCardClick(id);
      }}
    >
      <img src={img} alt="NFT" className="w-full h-full rounded-lg " />
      <div className="flex justify-between w-full absolute px-2 py-1 bottom-0">
        <p className="font-BubblegumSans text-[white] text-[14px]">
          {price} Artifacts
        </p>
        <p className="font-BubblegumSans text-[white] text-[14px]">#{id}</p>
      </div>
    </div>
  );
};

type CardProps = {
  img: string;
  price: number;
  id: number;
  onCardClick: (cardNumber: number) => void;
};

export default SellCard;
