import { toaster } from "../Components/Toast";
import { BASE_URL } from "../Common/config";

export const getCollections = async () => {
  try {
    let returnData;
    await fetch(`${BASE_URL}/collection-router/get`, {
      method: "GET",
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        returnData = res.data;
      });
    return returnData;
  } catch (error) {
    console.log(error);
    toaster("error", "Get Collections Error. Please Try Again Later!");
    return [];
  }
};
