const MasterMind = () => {
  return (
    <div className="flex flex-col lg:pb-5 justify-center items-center text-center h-[100vh] mastermind__background-image">
      <div className="px-4 md:container mx-auto">
        <p className="font-BubblegumSans text-white text-9xl">COMING SOON</p>
        <p className="font-BubblegumSans text-white text-6xl mt-[20px]">
          UNDER CONTRCTION
        </p>
      </div>
    </div>
  );
};

export default MasterMind;
