import { createContext, useContext } from "react";
import { UserSessionProps } from "../PropsType";

export const UserContext = createContext<UserSessionProps>({
  walletAddress: "",
  setWalletAddress: () => {},
  timeDiff: 0,
  setTimeDiff: () => {},
});

export const useUserContext = () => useContext(UserContext);
