import { createRef, useState } from "react";
import { Link } from "react-router-dom";
import { Carousel, CarouselItem, CarouselRef } from "react-round-carousel";
import { Spinner } from "@material-tailwind/react";

import "react-round-carousel/src/index.css";

const CollectionsSlider = () => {
  const carouselRef = createRef<CarouselRef>();

  const items: CarouselItem[] = [
    {
      alt: "DigiMonkz Genesis 111",
      image: `https://i.seadn.io/gae/bbug5CM_cHZoHQ9utRciFLvTW4c88hX5JIqadAcSj3XK2onoL5IOBsBzbY0-uB5NxfWrqs1722ITJ_NXQ0_MIKwN_nJfN6tFgQ_j?auto=format&dpr=1&w=1000`,
      content: (
        <Link
          to="https://opensea.io/assets/ethereum/0xf39e4fc07405e408befd2a5f138810e90f0a837c/12"
          target="_blank"
        >
          <strong>DigiMonkz Genesis 111</strong>
          <span>DigiMonkz Genesis #12</span>
        </Link>
      ),
    },
    {
      alt: "DigiMonkz Genesis 111",
      image: `https://i.seadn.io/gcs/files/8f70c8e4dda7fc441c9b217e2f3dca3d.png?w=500&auto=format`,
      content: (
        <Link
          to="https://opensea.io/assets/ethereum/0xf39e4fc07405e408befd2a5f138810e90f0a837c/10"
          target="_blank"
        >
          <strong>DigiMonkz Genesis 111</strong>
          <span>Phoenix #10</span>
        </Link>
      ),
    },
    {
      alt: "DigiMonkz Genesis 111",
      image: `https://i.seadn.io/gae/pcVpwwDm3SM6w1kaTnrq4UAzJEwBqkPgXZMwSKzh4i7e5MQCFvBh9iZvqCnHjvpqdsvp0uGIvWvpsfQH5kT5wbdgkliaHTpL-y2H7pk?w=500&auto=format`,
      content: (
        <Link
          to="https://opensea.io/assets/ethereum/0xf39e4fc07405e408befd2a5f138810e90f0a837c/6"
          target="_blank"
        >
          <strong>DigiMonkz Genesis 111</strong>
          <span>Raiden #6</span>
        </Link>
      ),
    },
    {
      alt: "DigiMonkz Genesis 111",
      image: `https://i.seadn.io/gae/f_F_zVd1h73ykh1p-Ar6Z8-LYEnLyH-zVAkmXN1wkuIEU6xc4DZKZu_Irg9MPjaPz5NoFxEzQK4sVYp9SnrB7v6hzM-cqr45_cD7tdY?auto=format&dpr=1&w=1000`,
      content: (
        <Link
          to="https://opensea.io/assets/ethereum/0xf39e4fc07405e408befd2a5f138810e90f0a837c/51"
          target="_blank"
        >
          <strong>DigiMonkz Genesis 111</strong>
          <span>DigiMonkz Genesis #51</span>
        </Link>
      ),
    },
    {
      alt: "DigiMonkz Genesis 111",
      image: `https://i.seadn.io/gae/JGqSvNgKhZZF7jVYk5PGadn_XkRhblATWq-ue_HFdVtnHdFRcGC1X1dpCTIAHwt4-oHS6s5jLK8tApfkNbtbka6k_BXksPNnlTjA-g?w=500&auto=format`,
      content: (
        <Link
          to="https://opensea.io/assets/ethereum/0xf39e4fc07405e408befd2a5f138810e90f0a837c/34"
          target="_blank"
        >
          <strong>DigiMonkz Genesis 111</strong>
          <span>DigiMonkz #34</span>
        </Link>
      ),
    },
    {
      alt: "DigiMonkz Genesis 111",
      image: `https://i.seadn.io/gcs/files/8b9b04c5643a7588c8beed5c12ae041d.png?w=500&auto=format`,
      content: (
        <Link
          to="https://opensea.io/assets/ethereum/0xf39e4fc07405e408befd2a5f138810e90f0a837c/28"
          target="_blank"
        >
          <strong>DigiMonkz Genesis 111</strong>
          <span>DigiMonkz #28</span>
        </Link>
      ),
    },
    {
      alt: "DigiMonkz Genesis 111",
      image: `https://i.seadn.io/gae/eFyiWVTpxO7XTWkSWG3MTnACJXspce8rrVC0CRxoCErJHoGqA08oWIjIqFoFW9lCVLKc5wtc_xcEHT3LTTcxLrXzE1EDlfKQcQMkJOY?auto=format&dpr=1&w=1000`,
      content: (
        <Link
          to="https://opensea.io/assets/ethereum/0xf39e4fc07405e408befd2a5f138810e90f0a837c/86"
          target="_blank"
        >
          <strong>DigiMonkz Genesis 111</strong>
          <span>DigiMonkz Genesis #86</span>
        </Link>
      ),
    },
    {
      alt: "DigiMonkz Genesis 111",
      image: `https://i.seadn.io/gae/WHLoRKdT9Aa6I0Pw2B2eyF8pA9i6lhE08mrXNuq17ISU4Qk9qo8Z-jFYDpgdDlpSPjYKX3z6aNxFQZ_8ZBRbM4_EWqE67V3BIB4iMw?auto=format&dpr=1&w=1000`,
      content: (
        <Link
          to="https://opensea.io/assets/ethereum/0xf39e4fc07405e408befd2a5f138810e90f0a837c/69"
          target="_blank"
        >
          <strong>DigiMonkz Genesis 111</strong>
          <span>DigiMonkz Genesis #69</span>
        </Link>
      ),
    },
    {
      alt: "DigiMonkz Genesis 111",
      image: `https://i.seadn.io/gae/7ejJUxsf-78BNirFO-JqZBCDjGNz217hKw4OacdU716L6Nzp2UQ2eJB55snoX0E1wNMcTmRxM4XZZkJ6aGczSAI-HrxdClK1IIA7cw?w=500&auto=format`,
      content: (
        <Link
          to="https://opensea.io/assets/ethereum/0xf39e4fc07405e408befd2a5f138810e90f0a837c/85"
          target="_blank"
        >
          <strong>DigiMonkz Genesis 111</strong>
          <span>DigiMonkz Genesis #85</span>
        </Link>
      ),
    },
    {
      alt: "DigiMonkz Genesis 111",
      image: `https://i.seadn.io/gae/F4MxmcmFQ2cLD2FNTLLtfYmkrNQvg7-13ejfcpI7H0jSnK3Kk1D3pK20t2KPhFcF03qvahnWYRtJ9CIIJOJAH021qoi-Q6in553QOIY?w=500&auto=format`,
      content: (
        <Link
          to="https://opensea.io/assets/ethereum/0xf39e4fc07405e408befd2a5f138810e90f0a837c/31"
          target="_blank"
        >
          <strong>DigiMonkz Genesis 111</strong>
          <span>DigiMonkz Genesis #31</span>
        </Link>
      ),
    },
    {
      alt: "DigiMonkz Genesis 111",
      image: `https://i.seadn.io/gae/SIirCubtL7rL6lNORdDn1Ve4PdOhzh6ROprM0XBV-1AMl-7oCP1H_sSGtL6G8L7zg1zgxty7cba41sgOIf1pNK64vLXYMMQ9csigARg?auto=format&dpr=1&w=1000`,
      content: (
        <Link
          to="https://opensea.io/assets/ethereum/0xf39e4fc07405e408befd2a5f138810e90f0a837c/29"
          target="_blank"
        >
          <strong>DigiMonkz Genesis 111</strong>
          <span>DigiMonkz Genesis #29</span>
        </Link>
      ),
    },
    {
      alt: "DigiMonkz Genesis 111",
      image: `https://i.seadn.io/gae/6dmt-VC-JCBDuoTCDFTJHeBB5gIW9XRNz-HvrP1hvEQq6TaFFoc_a_kSfurtHUEe6cRH3DvNK5MaRXJUOMh8_Nkwm8UkDDNXsQBonw?w=500&auto=format`,
      content: (
        <Link
          to="https://opensea.io/assets/ethereum/0xf39e4fc07405e408befd2a5f138810e90f0a837c/23"
          target="_blank"
        >
          <strong>DigiMonkz Genesis 111</strong>
          <span>DigiMonkz Genesis #23</span>
        </Link>
      ),
    },
    {
      alt: "DigiMonkz Genesis 111",
      image: `https://i.seadn.io/gae/bbug5CM_cHZoHQ9utRciFLvTW4c88hX5JIqadAcSj3XK2onoL5IOBsBzbY0-uB5NxfWrqs1722ITJ_NXQ0_MIKwN_nJfN6tFgQ_j?auto=format&dpr=1&w=1000`,
      content: (
        <Link
          to="https://opensea.io/assets/ethereum/0xf39e4fc07405e408befd2a5f138810e90f0a837c/12"
          target="_blank"
        >
          <strong>DigiMonkz Genesis 111</strong>
          <span>DigiMonkz Genesis #12</span>
        </Link>
      ),
    },
    {
      alt: "DigiMonkz Genesis 111",
      image: `https://i.seadn.io/gcs/files/8f70c8e4dda7fc441c9b217e2f3dca3d.png?w=500&auto=format`,
      content: (
        <Link
          to="https://opensea.io/assets/ethereum/0xf39e4fc07405e408befd2a5f138810e90f0a837c/10"
          target="_blank"
        >
          <strong>DigiMonkz Genesis 111</strong>
          <span>Phoenix #10</span>
        </Link>
      ),
    },
    {
      alt: "DigiMonkz Genesis 111",
      image: `https://i.seadn.io/gae/pcVpwwDm3SM6w1kaTnrq4UAzJEwBqkPgXZMwSKzh4i7e5MQCFvBh9iZvqCnHjvpqdsvp0uGIvWvpsfQH5kT5wbdgkliaHTpL-y2H7pk?w=500&auto=format`,
      content: (
        <Link
          to="https://opensea.io/assets/ethereum/0xf39e4fc07405e408befd2a5f138810e90f0a837c/6"
          target="_blank"
        >
          <strong>DigiMonkz Genesis 111</strong>
          <span>Raiden #6</span>
        </Link>
      ),
    },
    {
      alt: "DigiMonkz Genesis 111",
      image: `https://i.seadn.io/gae/f_F_zVd1h73ykh1p-Ar6Z8-LYEnLyH-zVAkmXN1wkuIEU6xc4DZKZu_Irg9MPjaPz5NoFxEzQK4sVYp9SnrB7v6hzM-cqr45_cD7tdY?auto=format&dpr=1&w=1000`,
      content: (
        <Link
          to="https://opensea.io/assets/ethereum/0xf39e4fc07405e408befd2a5f138810e90f0a837c/51"
          target="_blank"
        >
          <strong>DigiMonkz Genesis 111</strong>
          <span>DigiMonkz Genesis #51</span>
        </Link>
      ),
    },
    {
      alt: "DigiMonkz Genesis 111",
      image: `https://i.seadn.io/gae/JGqSvNgKhZZF7jVYk5PGadn_XkRhblATWq-ue_HFdVtnHdFRcGC1X1dpCTIAHwt4-oHS6s5jLK8tApfkNbtbka6k_BXksPNnlTjA-g?w=500&auto=format`,
      content: (
        <Link
          to="https://opensea.io/assets/ethereum/0xf39e4fc07405e408befd2a5f138810e90f0a837c/34"
          target="_blank"
        >
          <strong>DigiMonkz Genesis 111</strong>
          <span>DigiMonkz #34</span>
        </Link>
      ),
    },
    {
      alt: "DigiMonkz Genesis 111",
      image: `https://i.seadn.io/gcs/files/8b9b04c5643a7588c8beed5c12ae041d.png?w=500&auto=format`,
      content: (
        <Link
          to="https://opensea.io/assets/ethereum/0xf39e4fc07405e408befd2a5f138810e90f0a837c/28"
          target="_blank"
        >
          <strong>DigiMonkz Genesis 111</strong>
          <span>DigiMonkz #28</span>
        </Link>
      ),
    },
    {
      alt: "DigiMonkz Genesis 111",
      image: `https://i.seadn.io/gae/eFyiWVTpxO7XTWkSWG3MTnACJXspce8rrVC0CRxoCErJHoGqA08oWIjIqFoFW9lCVLKc5wtc_xcEHT3LTTcxLrXzE1EDlfKQcQMkJOY?auto=format&dpr=1&w=1000`,
      content: (
        <Link
          to="https://opensea.io/assets/ethereum/0xf39e4fc07405e408befd2a5f138810e90f0a837c/86"
          target="_blank"
        >
          <strong>DigiMonkz Genesis 111</strong>
          <span>DigiMonkz Genesis #86</span>
        </Link>
      ),
    },
    {
      alt: "DigiMonkz Genesis 111",
      image: `https://i.seadn.io/gae/WHLoRKdT9Aa6I0Pw2B2eyF8pA9i6lhE08mrXNuq17ISU4Qk9qo8Z-jFYDpgdDlpSPjYKX3z6aNxFQZ_8ZBRbM4_EWqE67V3BIB4iMw?auto=format&dpr=1&w=1000`,
      content: (
        <Link
          to="https://opensea.io/assets/ethereum/0xf39e4fc07405e408befd2a5f138810e90f0a837c/69"
          target="_blank"
        >
          <strong>DigiMonkz Genesis 111</strong>
          <span>DigiMonkz Genesis #69</span>
        </Link>
      ),
    },
    {
      alt: "DigiMonkz Genesis 111",
      image: `https://i.seadn.io/gae/7ejJUxsf-78BNirFO-JqZBCDjGNz217hKw4OacdU716L6Nzp2UQ2eJB55snoX0E1wNMcTmRxM4XZZkJ6aGczSAI-HrxdClK1IIA7cw?w=500&auto=format`,
      content: (
        <Link
          to="https://opensea.io/assets/ethereum/0xf39e4fc07405e408befd2a5f138810e90f0a837c/85"
          target="_blank"
        >
          <strong>DigiMonkz Genesis 111</strong>
          <span>DigiMonkz Genesis #85</span>
        </Link>
      ),
    },
    {
      alt: "DigiMonkz Genesis 111",
      image: `https://i.seadn.io/gae/F4MxmcmFQ2cLD2FNTLLtfYmkrNQvg7-13ejfcpI7H0jSnK3Kk1D3pK20t2KPhFcF03qvahnWYRtJ9CIIJOJAH021qoi-Q6in553QOIY?w=500&auto=format`,
      content: (
        <Link
          to="https://opensea.io/assets/ethereum/0xf39e4fc07405e408befd2a5f138810e90f0a837c/31"
          target="_blank"
        >
          <strong>DigiMonkz Genesis 111</strong>
          <span>DigiMonkz Genesis #31</span>
        </Link>
      ),
    },
    {
      alt: "DigiMonkz Genesis 111",
      image: `https://i.seadn.io/gae/SIirCubtL7rL6lNORdDn1Ve4PdOhzh6ROprM0XBV-1AMl-7oCP1H_sSGtL6G8L7zg1zgxty7cba41sgOIf1pNK64vLXYMMQ9csigARg?auto=format&dpr=1&w=1000`,
      content: (
        <Link
          to="https://opensea.io/assets/ethereum/0xf39e4fc07405e408befd2a5f138810e90f0a837c/29"
          target="_blank"
        >
          <strong>DigiMonkz Genesis 111</strong>
          <span>DigiMonkz Genesis #29</span>
        </Link>
      ),
    },
    {
      alt: "DigiMonkz Genesis 111",
      image: `https://i.seadn.io/gae/6dmt-VC-JCBDuoTCDFTJHeBB5gIW9XRNz-HvrP1hvEQq6TaFFoc_a_kSfurtHUEe6cRH3DvNK5MaRXJUOMh8_Nkwm8UkDDNXsQBonw?w=500&auto=format`,
      content: (
        <Link
          to="https://opensea.io/assets/ethereum/0xf39e4fc07405e408befd2a5f138810e90f0a837c/23"
          target="_blank"
        >
          <strong>DigiMonkz Genesis 111</strong>
          <span>DigiMonkz Genesis #23</span>
        </Link>
      ),
    },
  ];

  const items2: CarouselItem[] = [
    {
      alt: "DigiMonkz Gen 2",
      image: `https://i.seadn.io/gcs/files/c7bcc53b5658e1bd1cf46b5a6b1a12b4.png?w=500&auto=format`,
      content: (
        <Link
          to="https://opensea.io/assets/ethereum/0x3067599c11118d2d4c708d119820775425af029c/248"
          target="_blank"
        >
          <strong>DigiMonkz Gen 2</strong>
          <span>DigiMonkzGen2 #248</span>
        </Link>
      ),
    },
    {
      alt: "DigiMonkz Gen 2",
      image: `https://i.seadn.io/gcs/files/28a9ec2d769968c81e6b88e4f8f20e54.png?w=500&auto=format`,
      content: (
        <Link
          to="https://opensea.io/assets/ethereum/0x3067599c11118d2d4c708d119820775425af029c/177"
          target="_blank"
        >
          <strong>DigiMonkz Gen 2</strong>
          <span>DigiMonkzGen2 #177</span>
        </Link>
      ),
    },
    {
      alt: "DigiMonkz Gen 2",
      image: `https://i.seadn.io/gcs/files/b67e00ca3272ed0ea9befa3140f4b56a.png?w=500&auto=format`,
      content: (
        <Link
          to="https://opensea.io/assets/ethereum/0x3067599c11118d2d4c708d119820775425af029c/222"
          target="_blank"
        >
          <strong>DigiMonkz Gen 2</strong>
          <span>DigiMonkzGen2 #222</span>
        </Link>
      ),
    },
    {
      alt: "DigiMonkz Gen 2",
      image: `https://i.seadn.io/gcs/files/8bfe83683f40fc56f0c32bcd489d0ec7.png?w=500&auto=format`,
      content: (
        <Link
          to="https://opensea.io/assets/ethereum/0x3067599c11118d2d4c708d119820775425af029c/15"
          target="_blank"
        >
          <strong>DigiMonkz Gen 2</strong>
          <span>DigiMonkzGen2 #15</span>
        </Link>
      ),
    },
    {
      alt: "DigiMonkz Gen 2",
      image: `https://i.seadn.io/gcs/files/946f0d1b5c8833642798b6d94623b18d.png?w=500&auto=format`,
      content: (
        <Link
          to="https://opensea.io/assets/ethereum/0x3067599c11118d2d4c708d119820775425af029c/14"
          target="_blank"
        >
          <strong>DigiMonkz Gen 2</strong>
          <span>DigiMonkzGen2 #14</span>
        </Link>
      ),
    },
    {
      alt: "DigiMonkz Gen 2",
      image: `https://i.seadn.io/gcs/files/058820002893850b373086c63eae6f7c.png?w=500&auto=format`,
      content: (
        <Link
          to="https://opensea.io/assets/ethereum/0x3067599c11118d2d4c708d119820775425af029c/75"
          target="_blank"
        >
          <strong>DigiMonkz Gen 2</strong>
          <span>DigiMonkzGen2 #75</span>
        </Link>
      ),
    },
    {
      alt: "DigiMonkz Gen 2",
      image: `https://i.seadn.io/gcs/files/3c3181e9c5ac9e5467f128abb3b707fa.png?w=500&auto=format`,
      content: (
        <Link
          to="https://opensea.io/assets/ethereum/0x3067599c11118d2d4c708d119820775425af029c/169"
          target="_blank"
        >
          <strong>DigiMonkz Gen 2</strong>
          <span>DigiMonkzGen2 #169</span>
        </Link>
      ),
    },
    {
      alt: "DigiMonkz Gen 2",
      image: `https://i.seadn.io/gcs/files/3a27128a124fdf445770e8e063006aee.png?w=500&auto=format`,
      content: (
        <Link
          to="https://opensea.io/assets/ethereum/0x3067599c11118d2d4c708d119820775425af029c/114"
          target="_blank"
        >
          <strong>DigiMonkz Gen 2</strong>
          <span>DigiMonkzGen2 #114</span>
        </Link>
      ),
    },
    {
      alt: "DigiMonkz Gen 2",
      image: `https://i.seadn.io/gcs/files/4a67bc442a8dcb4c4cb5c6b465c61d3e.png?w=500&auto=format`,
      content: (
        <Link
          to="https://opensea.io/assets/ethereum/0x3067599c11118d2d4c708d119820775425af029c/31"
          target="_blank"
        >
          <strong>DigiMonkz Gen 2</strong>
          <span>DigiMonkzGen2 #31</span>
        </Link>
      ),
    },
    {
      alt: "DigiMonkz Gen 2",
      image: `https://i.seadn.io/gcs/files/53510342af6f5a279e818caee257b803.png?w=500&auto=format`,
      content: (
        <Link
          to="https://opensea.io/assets/ethereum/0x3067599c11118d2d4c708d119820775425af029c/378"
          target="_blank"
        >
          <strong>DigiMonkz Gen 2</strong>
          <span>DigiMonkzGen2 #378</span>
        </Link>
      ),
    },
    {
      alt: "DigiMonkz Gen 2",
      image: `https://i.seadn.io/gcs/files/93032e3744d82f83004a65df9086c685.png?w=500&auto=format`,
      content: (
        <Link
          to="https://opensea.io/assets/ethereum/0x3067599c11118d2d4c708d119820775425af029c/284"
          target="_blank"
        >
          <strong>DigiMonkz Gen 2</strong>
          <span>DigiMonkzGen2 #284</span>
        </Link>
      ),
    },
    {
      alt: "DigiMonkz Gen 2",
      image: `https://i.seadn.io/gcs/files/3a4e81c2eaae47a1f563b8d590e8e469.png?w=500&auto=format`,
      content: (
        <Link
          to="https://opensea.io/assets/ethereum/0x3067599c11118d2d4c708d119820775425af029c/36"
          target="_blank"
        >
          <strong>DigiMonkz Gen 2</strong>
          <span>DigiMonkzGen2 #36</span>
        </Link>
      ),
    },
    {
      alt: "DigiMonkz Gen 2",
      image: `https://i.seadn.io/gcs/files/c7bcc53b5658e1bd1cf46b5a6b1a12b4.png?w=500&auto=format`,
      content: (
        <Link
          to="https://opensea.io/assets/ethereum/0x3067599c11118d2d4c708d119820775425af029c/248"
          target="_blank"
        >
          <strong>DigiMonkz Gen 2</strong>
          <span>DigiMonkzGen2 #248</span>
        </Link>
      ),
    },
    {
      alt: "DigiMonkz Gen 2",
      image: `https://i.seadn.io/gcs/files/28a9ec2d769968c81e6b88e4f8f20e54.png?w=500&auto=format`,
      content: (
        <Link
          to="https://opensea.io/assets/ethereum/0x3067599c11118d2d4c708d119820775425af029c/177"
          target="_blank"
        >
          <strong>DigiMonkz Gen 2</strong>
          <span>DigiMonkzGen2 #177</span>
        </Link>
      ),
    },
    {
      alt: "DigiMonkz Gen 2",
      image: `https://i.seadn.io/gcs/files/b67e00ca3272ed0ea9befa3140f4b56a.png?w=500&auto=format`,
      content: (
        <Link
          to="https://opensea.io/assets/ethereum/0x3067599c11118d2d4c708d119820775425af029c/222"
          target="_blank"
        >
          <strong>DigiMonkz Gen 2</strong>
          <span>DigiMonkzGen2 #222</span>
        </Link>
      ),
    },
    {
      alt: "DigiMonkz Gen 2",
      image: `https://i.seadn.io/gcs/files/8bfe83683f40fc56f0c32bcd489d0ec7.png?w=500&auto=format`,
      content: (
        <Link
          to="https://opensea.io/assets/ethereum/0x3067599c11118d2d4c708d119820775425af029c/15"
          target="_blank"
        >
          <strong>DigiMonkz Gen 2</strong>
          <span>DigiMonkzGen2 #15</span>
        </Link>
      ),
    },
    {
      alt: "DigiMonkz Gen 2",
      image: `https://i.seadn.io/gcs/files/946f0d1b5c8833642798b6d94623b18d.png?w=500&auto=format`,
      content: (
        <Link
          to="https://opensea.io/assets/ethereum/0x3067599c11118d2d4c708d119820775425af029c/14"
          target="_blank"
        >
          <strong>DigiMonkz Gen 2</strong>
          <span>DigiMonkzGen2 #14</span>
        </Link>
      ),
    },
    {
      alt: "DigiMonkz Gen 2",
      image: `https://i.seadn.io/gcs/files/058820002893850b373086c63eae6f7c.png?w=500&auto=format`,
      content: (
        <Link
          to="https://opensea.io/assets/ethereum/0x3067599c11118d2d4c708d119820775425af029c/75"
          target="_blank"
        >
          <strong>DigiMonkz Gen 2</strong>
          <span>DigiMonkzGen2 #75</span>
        </Link>
      ),
    },
    {
      alt: "DigiMonkz Gen 2",
      image: `https://i.seadn.io/gcs/files/3c3181e9c5ac9e5467f128abb3b707fa.png?w=500&auto=format`,
      content: (
        <Link
          to="https://opensea.io/assets/ethereum/0x3067599c11118d2d4c708d119820775425af029c/169"
          target="_blank"
        >
          <strong>DigiMonkz Gen 2</strong>
          <span>DigiMonkzGen2 #169</span>
        </Link>
      ),
    },
    {
      alt: "DigiMonkz Gen 2",
      image: `https://i.seadn.io/gcs/files/3a27128a124fdf445770e8e063006aee.png?w=500&auto=format`,
      content: (
        <Link
          to="https://opensea.io/assets/ethereum/0x3067599c11118d2d4c708d119820775425af029c/114"
          target="_blank"
        >
          <strong>DigiMonkz Gen 2</strong>
          <span>DigiMonkzGen2 #114</span>
        </Link>
      ),
    },
    {
      alt: "DigiMonkz Gen 2",
      image: `https://i.seadn.io/gcs/files/4a67bc442a8dcb4c4cb5c6b465c61d3e.png?w=500&auto=format`,
      content: (
        <Link
          to="https://opensea.io/assets/ethereum/0x3067599c11118d2d4c708d119820775425af029c/31"
          target="_blank"
        >
          <strong>DigiMonkz Gen 2</strong>
          <span>DigiMonkzGen2 #31</span>
        </Link>
      ),
    },
    {
      alt: "DigiMonkz Gen 2",
      image: `https://i.seadn.io/gcs/files/53510342af6f5a279e818caee257b803.png?w=500&auto=format`,
      content: (
        <Link
          to="https://opensea.io/assets/ethereum/0x3067599c11118d2d4c708d119820775425af029c/378"
          target="_blank"
        >
          <strong>DigiMonkz Gen 2</strong>
          <span>DigiMonkzGen2 #378</span>
        </Link>
      ),
    },
    {
      alt: "DigiMonkz Gen 2",
      image: `https://i.seadn.io/gcs/files/93032e3744d82f83004a65df9086c685.png?w=500&auto=format`,
      content: (
        <Link
          to="https://opensea.io/assets/ethereum/0x3067599c11118d2d4c708d119820775425af029c/284"
          target="_blank"
        >
          <strong>DigiMonkz Gen 2</strong>
          <span>DigiMonkzGen2 #284</span>
        </Link>
      ),
    },
    {
      alt: "DigiMonkz Gen 2",
      image: `https://i.seadn.io/gcs/files/3a4e81c2eaae47a1f563b8d590e8e469.png?w=500&auto=format`,
      content: (
        <Link
          to="https://opensea.io/assets/ethereum/0x3067599c11118d2d4c708d119820775425af029c/36"
          target="_blank"
        >
          <strong>DigiMonkz Gen 2</strong>
          <span>DigiMonkzGen2 #36</span>
        </Link>
      ),
    },
  ];

  const [showItems, setShowItems] = useState<CarouselItem[]>(items);
  const [selectNFT, setSelectNFT] = useState(0);
  const [loading, setLoading] = useState(false);

  const updateItems = (nft: number) => {
    setLoading(true);
    setSelectNFT(nft);
    if (nft === 0) {
      setShowItems(items);
    } else {
      setShowItems(items2);
    }
    setLoading(false);
  };

  return (
    <div>
      <div className="flex justify-center items-center mt-[10px]">
        <div
          className={`${
            selectNFT === 0
              ? "border-[rgb(44,52,65)] border-[3px] rounded-[50%] p-1"
              : ""
          }`}
          onClick={() => {
            updateItems(0);
          }}
        >
          <img
            src="https://i.seadn.io/gcs/files/4bbf53de963f24f668b785607a3726b6.png?w=500&auto=format"
            alt="First NFT"
            className={`border-[1px] border-white rounded-[50%] cursor-pointer w-[40px] h-[40px] transition-all duration-500 ${
              selectNFT === 0 ? "scale-100" : "scale-90"
            }`}
          />
        </div>
        <div
          className={`ml-[10px] ${
            selectNFT === 1
              ? "border-[rgb(44,52,65)] border-[3px] rounded-[50%] p-1"
              : ""
          }`}
          onClick={() => {
            updateItems(1);
          }}
        >
          <img
            src="https://i.seadn.io/gcs/files/08ffec5e847f4803b36c5371498ae4ce.jpg?w=500&auto=format"
            alt="Second NFT"
            className={`border-[1px] border-white rounded-[50%] cursor-pointer w-[40px] h-[40px] transition-all duration-500 ${
              selectNFT === 1 ? "scale-100" : "scale-90"
            }`}
          />
        </div>
      </div>
      <div className="relative mt-[20px]">
        {loading ? (
          <div className="flex justify-center items-center h-[210px]">
            <Spinner className="h-12 w-12" color="teal" />
          </div>
        ) : (
          <Carousel
            ref={carouselRef}
            items={showItems}
            slideOnClick
            showControls={true}
          />
        )}
      </div>
    </div>
  );
};

export default CollectionsSlider;
