import { Fragment, useState } from "react";
import { Dialog, DialogHeader, DialogBody } from "@material-tailwind/react";
import { useContract, useSigner } from "wagmi";

import { getNFTInfo } from "../../Hooks/Hook";

import {
  COLLECTION_FIRST,
  COLLECTION_SECOND,
  CONTRACT_ABI,
  CONTRACT_ADDRESS,
  UNKNOWN_IMG,
} from "../../Common/config";

const NFTInfoModal = (props: INFTInfoModalProps) => {
  const { data: signerData } = useSigner();
  const [nftID, setNFTID] = useState(0);
  const [nftType, setNFTType] = useState(0);
  const [imgURL, setIMGUrl] = useState(UNKNOWN_IMG);
  const [artifactCnt, setArtifactCnt] = useState(0);
  const userContract = useContract({
    address: CONTRACT_ADDRESS,
    abi: CONTRACT_ABI,
    signerOrProvider: signerData,
  });

  const onNFTInfoClick = async () => {
    let res = await getNFTInfo(
      userContract,
      nftType === 0 ? COLLECTION_FIRST : COLLECTION_SECOND,
      nftID,
      nftType
    );
    setIMGUrl(res.imgURL);
    setArtifactCnt(res.artifact);
  };

  return (
    <Fragment>
      <Dialog
        open={props.open}
        handler={props.onHandleOpen}
        className="min-w-[350px] max-w-[600px]"
      >
        <DialogHeader>
          <div className="flex justify-center items-center w-full">
            <div
              className={`${
                nftType === 0
                  ? "border-[rgb(0,0,0,0.3)] border-[3px] rounded-[50%] p-1"
                  : ""
              }`}
              onClick={() => {
                setNFTType(0);
              }}
            >
              <img
                src="https://i.seadn.io/gcs/files/4bbf53de963f24f668b785607a3726b6.png?w=500&auto=format"
                alt="First NFT"
                className={`border-[1px] border-white rounded-[50%] cursor-pointer w-[40px] h-[40px] transition-all duration-500 ${
                  nftType === 0 ? "scale-100" : "scale-90"
                }`}
              />
            </div>
            <div
              className={`ml-[10px] ${
                nftType === 1
                  ? "border-[rgb(0,0,0,0.3)] border-[3px] rounded-[50%] p-1"
                  : ""
              }`}
              onClick={() => {
                setNFTType(1);
              }}
            >
              <img
                src="https://i.seadn.io/gcs/files/08ffec5e847f4803b36c5371498ae4ce.jpg?w=500&auto=format"
                alt="Second NFT"
                className={`border-[1px] border-white rounded-[50%] cursor-pointer w-[40px] h-[40px] transition-all duration-500 ${
                  nftType === 1 ? "scale-100" : "scale-90"
                }`}
              />
            </div>
          </div>
        </DialogHeader>
        <DialogBody className="grid place-items-center gap-4">
          <div className="flex w-full">
            <input
              className="bg-gray-400 shadow appearance-none border rounded py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline w-full font-BubblegumSans text-center text-2xl"
              id="mintNumber"
              type="number"
              placeholder="Please Input NFT ID"
              value={nftID}
              min={1}
              onChange={(e) => {
                setNFTID(Number(e.target.value));
              }}
            />
            <button
              className="text-xl text-[#0a1a1b] font-[400] transition-all font-BubblegumSans border-2 rounded-lg tracking-[1px] uppercase hover:border-[#5c9e90] hover:text-[#5c9e90] border-[#0a1a1b] w-[150px] ml-[20px]"
              onClick={onNFTInfoClick}
            >
              GET INFO
            </button>
          </div>
          <p className="font-BubblegumSans text-[#0a1a1b] text-xl">
            Artifacts Count:{" "}
            <span className="text-2xl text-[#5c9e90]">{artifactCnt}</span>
          </p>
          <div className="aspect-square w-full h-full">
            <img
              src={imgURL}
              alt="NFT"
              className="rounded-lg w-full h-full object-cover"
            />
          </div>
        </DialogBody>
      </Dialog>
    </Fragment>
  );
};

export interface INFTInfoModalProps {
  open: boolean;
  onHandleOpen: () => void;
}

export default NFTInfoModal;
