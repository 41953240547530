import { useState, useEffect } from "react";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useAccount } from "wagmi";
import { Link as LinkScroll } from "react-scroll";
import { Link } from "react-router-dom";
import { ethers } from "ethers";

import { toaster } from "../Toast";

import { useUserContext } from "../../Context/UserContext";

import { API_KEY, BASE_URL } from "../../Common/config";

import Digimonkz from "../../Assets/img/logo.png";

const Header = () => {
  const provider = new ethers.providers.WebSocketProvider(API_KEY);
  const { address } = useAccount();
  const { walletAddress, setWalletAddress, setTimeDiff } = useUserContext();

  const [hamburgerMenu, setHamburgerMenu] = useState(false);
  // const [collapseBookMenu, setCollapseBookMenu] = useState(false);
  // const [collapseMasterMenu, setCollapseMasterMenu] = useState(false);

  const updateTimeline = async () => {
    const currentBlock = await provider.getBlockNumber();
    const blockTimestamp = (await provider.getBlock(currentBlock)).timestamp;
    let comTime = new Date().getTime() / 1000;
    let blockTime = blockTimestamp;
    setTimeDiff(blockTime - comTime);
  };

  const getRealUpdateData = async (liveRealDataSSE: EventSource) => {
    liveRealDataSSE.addEventListener("message", async (event) => {
      if (
        JSON.parse(event.data).type === "insert" &&
        JSON.parse(event.data).init === false
      ) {
        console.log(JSON.parse(event.data).data);
        toaster(
          "success",
          `${JSON.parse(event.data).data.collectionName} ${
            JSON.parse(event.data).data.NFTID
          } Recieved`
        );
      }
    });
  };

  useEffect(() => {
    setWalletAddress(address ? address.toString() : "");
    updateTimeline();

    const liveRealDataSSE = new EventSource(
      `${BASE_URL}/user-router/get-real-data/${address}`,
      { withCredentials: true }
    );
    getRealUpdateData(liveRealDataSSE);

    return () => {
      liveRealDataSSE.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, setWalletAddress]);

  return (
    <div className="nav-main w-full top-0 left-0 z-40 fixed lg:before:hidden lg:backdrop-blur-sm transition-all duration-500 bg-[rgba(0,0,0,0.1)]">
      <div
        className={
          "flex flex-col lg:flex-row lg:min-h-0 lg:container mx-auto " +
          `${
            hamburgerMenu
              ? "min-h-screen h-screen pb-4 overflow-auto scrollbar-hide"
              : ""
          }`
        }
      >
        <div className="flex justify-center lg:items-center border-b border-transparent transition-all lg:!bg-transparent lg:border-0 !bg-[rgba(0,0,0,0.1)]">
          <div className="grow w-14 flex items-center justify-center lg:hidden"></div>
          <div className="grow-0 text-center">
            <div
              aria-current="page"
              className="router-LinkScroll-active router-LinkScroll-exact-active inline-block w-[138px] mx-1 lg:w-[165px] cursor-pointer"
            >
              <Link to="/">
                <img
                  src={Digimonkz}
                  alt="Digimonkz logo"
                  width="165"
                  height="24"
                  className="inline-block w-full lg:py-5 transition-all py-5"
                />
              </Link>
            </div>
          </div>
          <div className="grow w-14 flex justify-end items-center lg:hidden">
            <div
              className="px-3 flex h-full items-center cursor-pointer"
              onClick={() => {
                hamburgerMenu
                  ? setHamburgerMenu(false)
                  : setHamburgerMenu(true);
              }}
            >
              {hamburgerMenu ? (
                <div className="relative w-6 h-4 mt-[-44px] transition-all duration-300">
                  <span className="text-[35px] text-white">x</span>
                </div>
              ) : (
                <div className="relative w-6 h-4 transition-all duration-300">
                  <span className="rounded-full transition-all duration-300 border-b-2 block border-white w-6 absolute top-0 right-0 menu-burger:-rotate-45 menu-burger:top-2"></span>
                  <span className="rounded-full transition-all duration-300 border-b-2 block border-white w-4 absolute top-1/2 right-0 menu-burger:opacity-0"></span>
                  <span className="rounded-full transition-all duration-300 border-b-2 block border-white w-5 absolute top-full right-0 menu-burger:w-6 menu-burger:rotate-45 menu-burger:top-2"></span>
                </div>
              )}
            </div>
          </div>
          <div className="justify-center hidden lg:flex"></div>
        </div>
        <div
          className={
            "grow flex-col justify-evenly animate-fade-in lg:flex-row lg:flex lg:items-center lg:justify-end " +
            `${hamburgerMenu ? "flex" : "hidden"}`
          }
        >
          <ul className="lg:flex text-center">
            {/* <li className="uppercase text-lg tracking-wider py-5 lg:text-base whitespace-nowrap overflow-hidden relative transition-all after:absolute after:w-full after:h-1 after:left-0 after:bottom-0 after:rounded-t-full after:transition-all group px-2 lg:px-4 after:translate-y-3 cursor-pointer flex items-center justify-center">
              <LinkScroll
                className="inline-block my-3 text-2xl lg:text-lg text-white hover:text-[#5c9e90] font-[400] transition-all font-BubblegumSans"
                smooth
                spy
                to="partner"
                onClick={() => {
                  setHamburgerMenu(false);
                }}
              >
                <span>Partners</span>
              </LinkScroll>
            </li> */}
            <li className="uppercase text-lg tracking-wider py-5 lg:text-base whitespace-nowrap overflow-hidden relative transition-all after:absolute after:w-full after:h-1 after:left-0 after:bottom-0 after:rounded-t-full after:transition-all group px-2 lg:px-4 after:translate-y-3 cursor-pointer flex items-center justify-center">
              <LinkScroll
                className="inline-block my-3 text-2xl lg:text-lg text-white hover:text-[#5c9e90] font-[400] transition-all font-BubblegumSans"
                smooth
                spy
                to="team"
                onClick={() => {
                  setHamburgerMenu(false);
                }}
              >
                <span>Team</span>
              </LinkScroll>
            </li>
            {/* <li className="uppercase text-lg tracking-wider py-5 lg:text-base whitespace-nowrap relative transition-all after:absolute after:w-full after:h-1 after:left-0 after:bottom-0 after:rounded-t-full after:transition-all group px-2 lg:px-4 after:translate-y-3 cursor-pointer flex items-center justify-center">
              <Link
                to="https://www.amazon.com/Art-Stress-Free-Living-Reprogram-Inside/dp/B081GNWX85/ref=sr_1_1?crid=128I8KGLT3K93&keywords=the+art+of+stress+free+living+ramos&qid=1684334468&sprefix=the+art+of+stress+free+living+ramos%2Caps%2C97&sr=8-1"
                className="inline-block my-3 text-2xl lg:text-lg text-white hover:text-[#5c9e90] font-[400] transition-all font-BubblegumSans"
                onClick={() => {
                  setCollapseBookMenu(!collapseBookMenu);
                }}
              >
                <span>Book</span>
              </Link>
              <ul
                className={
                  "flex-col absolute z-20 bg-[rgba(0,0,0,0.3)] shadow-2xl rounded-xl top-[70px] left-[calc(50% - 25px)] lg:left-0 overflow-hidden " +
                  `${collapseBookMenu ? "flex" : "hidden"}`
                }
              >
                <li className="block whitespace-nowrap">
                  <Link
                    to="https://www.amazon.com/Art-Stress-Free-Living-Reprogram-Inside/dp/B081GNWX85/ref=sr_1_1?crid=128I8KGLT3K93&keywords=the+art+of+stress+free+living+ramos&qid=1684334468&sprefix=the+art+of+stress+free+living+ramos%2Caps%2C97&sr=8-1"
                    className="flex w-full items-center px-3 py-3 text-white font-BubblegumSans transition-all duration-100"
                    target="_blank"
                    onClick={() => {
                      setCollapseBookMenu(!collapseBookMenu);
                      setHamburgerMenu(false);
                    }}
                  >
                    <span className="text-lg">Book1</span>
                  </Link>
                </li>
                <li className="block whitespace-nowrap">
                  <Link
                    to="https://www.amazon.com/Art-Stress-Free-Living-Reprogram-Inside/dp/B081GNWX85/ref=sr_1_1?crid=128I8KGLT3K93&keywords=the+art+of+stress+free+living+ramos&qid=1684334468&sprefix=the+art+of+stress+free+living+ramos%2Caps%2C97&sr=8-1"
                    className="flex w-full items-center px-3 py-3 text-white font-BubblegumSans transition-all duration-100"
                    target="_blank"
                    onClick={() => {
                      setHamburgerMenu(false);
                      setCollapseBookMenu(!collapseBookMenu);
                    }}
                  >
                    <span className="text-lg">Book2</span>
                  </Link>
                </li>
              </ul>
            </li> */}
            <li className="uppercase text-lg tracking-wider py-5 lg:text-base whitespace-nowrap overflow-hidden relative transition-all after:absolute after:w-full after:h-1 after:left-0 after:bottom-0 after:rounded-t-full after:transition-all group px-2 lg:px-4 after:translate-y-3 cursor-pointer flex items-center justify-center">
              <LinkScroll
                className="inline-block my-3 text-2xl lg:text-lg text-white hover:text-[#5c9e90] font-[400] transition-all font-BubblegumSans"
                smooth
                spy
                to="collections"
                onClick={() => {
                  setHamburgerMenu(false);
                }}
              >
                <span>Collections</span>
              </LinkScroll>
            </li>
            {walletAddress !== "" && (
              <>
                <li className="uppercase text-lg tracking-wider py-5 lg:text-base whitespace-nowrap overflow-hidden relative transition-all after:absolute after:w-full after:h-1 after:left-0 after:bottom-0 after:rounded-t-full after:transition-all group px-2 lg:px-4 after:translate-y-3 cursor-pointer flex items-center justify-center">
                  <LinkScroll
                    className="inline-block my-3 text-2xl lg:text-lg text-white hover:text-[#5c9e90] font-[400] transition-all font-BubblegumSans"
                    smooth
                    spy
                    to="mynft"
                    onClick={() => {
                      setHamburgerMenu(false);
                    }}
                  >
                    <span>MINT NOW</span>
                  </LinkScroll>
                </li>
                <li className="uppercase text-lg tracking-wider py-5 lg:text-base whitespace-nowrap relative transition-all after:absolute after:w-full after:h-1 after:left-0 after:bottom-0 after:rounded-t-full after:transition-all group px-2 lg:px-4 after:translate-y-3 cursor-pointer flex items-center justify-center">
                  <Link
                    to="/staking"
                    className="inline-block my-3 text-2xl lg:text-lg text-white hover:text-[#5c9e90] font-[400] transition-all font-BubblegumSans"
                    onClick={() => {
                      setHamburgerMenu(false);
                    }}
                  >
                    <span>Meditation</span>
                  </Link>
                </li>
                <li className="uppercase text-lg tracking-wider py-5 lg:text-base whitespace-nowrap relative transition-all after:absolute after:w-full after:h-1 after:left-0 after:bottom-0 after:rounded-t-full after:transition-all group px-2 lg:px-4 after:translate-y-3 cursor-pointer flex items-center justify-center">
                  <Link
                    to="/treasure"
                    className="inline-block my-3 text-2xl lg:text-lg text-white hover:text-[#5c9e90] font-[400] transition-all font-BubblegumSans"
                    onClick={() => {
                      setHamburgerMenu(false);
                    }}
                  >
                    <span>Treasures</span>
                  </Link>
                </li>
                <li className="uppercase text-lg tracking-wider py-5 lg:text-base whitespace-nowrap relative transition-all after:absolute after:w-full after:h-1 after:left-0 after:bottom-0 after:rounded-t-full after:transition-all group px-2 lg:px-4 after:translate-y-3 cursor-pointer flex items-center justify-center">
                  <Link
                    to="/mastermind"
                    className="inline-block my-3 text-2xl lg:text-lg text-white hover:text-[#5c9e90] font-[400] transition-all font-BubblegumSans"
                    // onClick={() => {
                    //   setCollapseMasterMenu(!collapseMasterMenu);
                    // }}
                  >
                    <span>MASTERMIND</span>
                  </Link>
                  {/* <ul
                    className={
                      "flex-col absolute z-20 bg-[rgba(0,0,0,0.3)] shadow-2xl rounded-xl top-[70px] left-[calc(50% - 25px)] lg:left-0 overflow-hidden " +
                      `${collapseMasterMenu ? "flex" : "hidden"}`
                    }
                  >
                    <li className="block whitespace-nowrap">
                      <Link
                        to="/mastermind"
                        className="flex w-full items-center px-3 py-3 text-white font-BubblegumSans transition-all duration-100"
                        onClick={() => {
                          setCollapseMasterMenu(!collapseMasterMenu);
                          setHamburgerMenu(false);
                        }}
                      >
                        <span className="text-lg">MASTERMIND1</span>
                      </Link>
                    </li>
                    <li className="block whitespace-nowrap">
                      <Link
                        to="/mastermind"
                        className="flex w-full items-center px-3 py-3 text-white font-BubblegumSans transition-all duration-100"
                        onClick={() => {
                          setHamburgerMenu(false);
                          setCollapseMasterMenu(!collapseMasterMenu);
                        }}
                      >
                        <span className="text-lg">MASTERMIND2</span>
                      </Link>
                    </li>
                  </ul> */}
                </li>
              </>
            )}
            <li className="uppercase text-lg tracking-wider py-5 lg:text-base whitespace-nowrap overflow-hidden relative transition-all after:absolute after:w-full after:h-1 after:left-0 after:bottom-0 after:rounded-t-full after:transition-all group px-2 lg:px-4 after:translate-y-3 cursor-pointer flex items-center justify-center">
              <div className="inline-block my-3 text-2xl lg:text-lg text-white hover:text-[#5c9e90] font-[400] transition-all font-BubblegumSans border-4 px-5 py-2 rounded-xl">
                <ConnectButton.Custom>
                  {({
                    account,
                    chain,
                    openAccountModal,
                    openChainModal,
                    openConnectModal,
                    authenticationStatus,
                    mounted,
                  }) => {
                    // Note: If your app doesn't use authentication, you
                    // can remove all 'authenticationStatus' checks
                    const ready = mounted && authenticationStatus !== "loading";
                    const connected =
                      ready &&
                      account &&
                      chain &&
                      (!authenticationStatus ||
                        authenticationStatus === "authenticated");

                    return (
                      <div
                        {...(!ready && {
                          "aria-hidden": true,
                          style: {
                            opacity: 0,
                            pointerEvents: "none",
                            userSelect: "none",
                          },
                        })}
                      >
                        {(() => {
                          if (!connected) {
                            return (
                              <button
                                onClick={openConnectModal}
                                className="connect-wallet__btn font-BubblegumSans"
                                type="button"
                              >
                                PLEASE CONNECT YOUR WALLET
                              </button>
                            );
                          }

                          if (chain.unsupported) {
                            return (
                              <button onClick={openChainModal} type="button">
                                Wrong network
                              </button>
                            );
                          }

                          return (
                            <div
                              style={{ display: "flex", gap: 12 }}
                              className="font-BubblegumSans"
                            >
                              {/* <button
                                onClick={openChainModal}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                type="button"
                              >
                                {chain.hasIcon && (
                                  <div
                                    style={{
                                      background: chain.iconBackground,
                                      width: 12,
                                      height: 12,
                                      borderRadius: 999,
                                      overflow: "hidden",
                                      marginRight: 4,
                                    }}
                                  >
                                    {chain.iconUrl && (
                                      <img
                                        alt={chain.name ?? "Chain icon"}
                                        src={chain.iconUrl}
                                        style={{ width: 12, height: 12 }}
                                      />
                                    )}
                                  </div>
                                )}
                                {chain.name}
                              </button> */}

                              <button onClick={openAccountModal} type="button">
                                {account.displayName}
                                {account.displayBalance
                                  ? ` (${account.displayBalance})`
                                  : ""}
                              </button>
                            </div>
                          );
                        })()}
                      </div>
                    );
                  }}
                </ConnectButton.Custom>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Header;
