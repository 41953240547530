import { Link } from "react-router-dom";

import Digimonkz from "../../Assets/img/logo.png";
import OpenSea from "../../Assets/img/OpenSea.svg";
import Twitter from "../../Assets/img/Twitter.svg";
import Discord from "../../Assets/img/Discord.svg";
import Youtube from "../../Assets/img/Youtube.svg";

const Footer = () => {
  return (
    <div className="w-full bg-[#0a1a1b]">
      <footer className="mx-auto lg:container">
        <nav className="flex flex-col lg:flex-row lg:align-middle lg:items-center">
          <div className="w-full flex justify-center lg:justify-start">
            <div className="text-center max-w-[150px] lg:order-1 lg:mt-0 lg:flex lg:flex-wrap lg:justify-start lg:text-left lg:max-w-[165px] xl:max-w-[165px]">
              <img
                src={Digimonkz}
                alt="Digimonkz logo"
                width="165"
                height="24"
                className="inline-block w-full lg:py-5 transition-all py-5"
              />
            </div>
          </div>
          <div className="w-full lg:grow text-center lg:order-2 lg:mt-0">
            <ul className="flex justify-center lg:justify-end 2xl:justify-end">
              <li className="lg:inline-block mr-[30px]">
                <Link
                  to="https://opensea.io/collection/digimonkz"
                  target="_blank"
                >
                  <img
                    src={OpenSea}
                    alt="OpenSea"
                    className="w-[40px] h-[40px]  hover:scale-125"
                  />
                </Link>
              </li>
              <li className="lg:inline-block mr-[30px]">
                <Link to="http://twitter.com/DigiMonkz" target="_blank">
                  <img
                    src={Twitter}
                    alt="Twitter"
                    className="w-[40px] h-[40px]  hover:scale-125"
                  />
                </Link>
              </li>
              <li className="lg:inline-block mr-[30px]">
                <Link to="https://discord.gg/digimonkz" target="_blank">
                  <img
                    src={Discord}
                    alt="Discord"
                    className="w-[40px] h-[40px]  hover:scale-125"
                  />
                </Link>
              </li>
              <li className="lg:inline-block">
                <Link to="https://www.youtube.com/@DigiMonkz" target="_blank">
                  <img
                    src={Youtube}
                    alt="Youtube"
                    className="w-[40px] h-[40px]  hover:scale-125"
                  />
                </Link>
              </li>
            </ul>
          </div>
        </nav>

        <div className="w-full block text-center pb-[10px]">
          <span className="text-base lg:text-lg 2xl:text-xl tracking-widest font-[400] text-white font-BubblegumSans">
            Digimonkznft@gmail.com
          </span>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
