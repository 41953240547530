import { useState, useEffect, useRef, useCallback } from "react";
import { Link } from "react-router-dom";

import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";

import { useContract, useSigner } from "wagmi";
import {
  CONTRACT_ABI,
  CONTRACT_ADDRESS,
  TEST_VERSION,
} from "../../Common/config";

import Card from "../../Components/Card";
import Spin from "../../Components/Spin";

import { useUserContext } from "../../Context/UserContext";
import {
  getUserNFTList,
  getNFT1Info,
  getNFT2Info,
  nft1Stake,
  nft1UnStake,
  nft2Stake,
  nft2UnStake,
  nft1GetArtifact,
  nft2GetArtifact,
  getNFTInfo,
} from "../../Hooks/Hook";

import {
  NFT1_CLAIM_DATE1,
  NFT1_CLAIM_DATE2,
  NFT2_CLAIM_DATE1,
  NFT2_CLAIM_DATE2,
  COLLECTION_FIRST,
  COLLECTION_SECOND,
  // TEST_NFT_ABI,
  DIGI1_ABI,
  DIGI2_ABI,
} from "../../Common/config";

import { remainDate } from "../../Common/utils";

import { INFTType } from "../../PropsType/index";
import { toaster } from "../../Components/Toast";

const Staking = () => {
  const intervalRef: any = useRef();

  const { data: signerData } = useSigner();
  const { walletAddress, timeDiff } = useUserContext();

  const userContract = useContract({
    address: CONTRACT_ADDRESS,
    abi: CONTRACT_ABI,
    signerOrProvider: signerData,
  });
  const nft1Contract = useContract({
    address: COLLECTION_FIRST,
    abi: DIGI1_ABI,
    signerOrProvider: signerData,
  });
  const nft2Contract = useContract({
    address: COLLECTION_SECOND,
    abi: DIGI2_ABI,
    signerOrProvider: signerData,
  });

  const [, setCalcTimer] = useState(0);
  const [selectNFT, setSelectNFT] = useState(0);
  const [loading, setLoading] = useState(false);
  const [accordionOpen, setAccordionOpen] = useState(1);
  const [stakedNFTs, setStakedNFTs] = useState<INFTType[]>([]);
  const [unStakeNFTs, setUnStakeNFTs] = useState<INFTType[]>([]);
  const [selectStakeNFTs, setSelectStakeNFTs] = useState<number[]>([]);
  const [selectUnStakeNFTs, setSelectUnStakeNFTs] = useState<number[]>([]);
  const [rewardNFT, setRewardNFT] = useState<number[]>([]);

  const increateTimer = () => setCalcTimer((prev) => prev + 1);

  const updateItems = (nft: number) => {
    setSelectNFT(nft);
    getUserNFTs(nft);
    setSelectStakeNFTs([]);
    setRewardNFT([]);
    setSelectUnStakeNFTs([]);
  };

  const getUserNFTs = useCallback(
    async (nftNumber: number) => {
      setLoading(true);
      let stakedItems: INFTType[] = [];
      let unStakedItems: INFTType[] = [];
      // let userNFTs = await getNFT(walletAddress, nftNumber);
      let userNFTs = await getUserNFTList(
        nftNumber === 0 ? nft1Contract : nft2Contract,
        walletAddress
      );
      const res: any =
        nftNumber === 0
          ? await getNFT1Info(userContract, walletAddress)
          : await getNFT2Info(userContract, walletAddress);

      for (const item of userNFTs) {
        console.log(Number(item));
        let searchItem: any;
        res.forEach((nfts: any) => {
          if (nfts.NFTID === Number(item)) searchItem = nfts;
        });
        let resNFT = await getNFTInfo(
          userContract,
          nftNumber === 0 ? COLLECTION_FIRST : COLLECTION_SECOND,
          Number(item),
          nftNumber
        );
        console.log(searchItem);
        if (searchItem)
          stakedItems.push({
            NFTID: item,
            NFTImage: resNFT.imgURL,
            StakedAt: searchItem.StakedAt,
            Artifact: searchItem.Artifact,
            LastClaimedAt: searchItem.LastClaimedAt,
          });
        else
          unStakedItems.push({
            NFTID: item,
            NFTImage: resNFT.imgURL,
            StakedAt: 0,
            Artifact: 0,
            LastClaimedAt: 0,
          });
      }
      // for (const stakeItems of res) {
      //   let res = await getNFTInfo(
      //     userContract,
      //     nftNumber === 0 ? COLLECTION_FIRST : COLLECTION_SECOND,
      //     stakeItems.NFTID,
      //     nftNumber
      //   );
      //   console.log(stakeItems.NFTID);
      //   stakedItems.push({
      //     NFTID: stakeItems.NFTID,
      //     NFTImage: res.imgURL,
      //     StakedAt: stakeItems.StakedAt,
      //     Artifact: stakeItems.Artifact,
      //     LastClaimedAt: stakeItems.LastClaimedAt,
      //   });
      // }
      // for (const item of userNFTs) {
      //   let res = await getNFTInfo(
      //     userContract,
      //     nftNumber === 0 ? COLLECTION_FIRST : COLLECTION_SECOND,
      //     Number(item.NFTID),
      //     nftNumber
      //   );
      //   unStakedItems.push({
      //     NFTID: item.NFTID,
      //     NFTImage: res.imgURL,
      //     StakedAt: 0,
      //     Artifact: 0,
      //     LastClaimedAt: 0,
      //   });
      // }
      setStakedNFTs(stakedItems);
      console.log(stakedItems);
      setUnStakeNFTs(unStakedItems);
      setLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [walletAddress]
  );

  const stakeNFTs = async () => {
    setLoading(true);

    let res =
      selectNFT === 0
        ? await nft1Stake(
            userContract,
            // nft1Contract,
            // walletAddress,
            selectStakeNFTs
          )
        : await nft2Stake(
            userContract,
            // nft2Contract,
            // walletAddress,
            selectStakeNFTs
          );
    if (res) {
      toaster("success", `${selectStakeNFTs.length} NFTs Staked`);
      setSelectStakeNFTs([]);
      setTimeout(() => getUserNFTs(selectNFT), 500);
    }
    setLoading(false);
  };

  const unStakeNFTsAgain = async () => {
    setLoading(true);
    let res =
      selectNFT === 0
        ? await nft1UnStake(userContract, selectUnStakeNFTs)
        : await nft2UnStake(userContract, selectUnStakeNFTs);
    if (res) {
      toaster("success", `${selectUnStakeNFTs.length} NFTs UnStaked`);
      setSelectUnStakeNFTs([]);
      setRewardNFT([]);
      setTimeout(() => getUserNFTs(selectNFT), 500);
    }
    setLoading(false);
  };

  const onStakeClick = (nftNumber: number) => {
    const index = selectStakeNFTs.indexOf(nftNumber);
    if (index !== -1) selectStakeNFTs.splice(index, 1);
    else setSelectStakeNFTs((prev) => [...prev, nftNumber]);
  };

  const onUnStakeClick = (nftNumber: number, rewardPossible: boolean) => {
    console.log(rewardPossible);
    const index = selectUnStakeNFTs.indexOf(nftNumber);
    if (index !== -1) selectUnStakeNFTs.splice(index, 1);
    else setSelectUnStakeNFTs((prev) => [...prev, nftNumber]);
    if (!rewardPossible) {
      const index1 = rewardNFT.indexOf(nftNumber);
      if (index1 !== -1) rewardNFT.splice(index1, 1);
      else setRewardNFT((prev) => [...prev, nftNumber]);
    }
  };

  const getArtifacts = async () => {
    setLoading(true);
    let res =
      selectNFT === 0
        ? await nft1GetArtifact(userContract, selectUnStakeNFTs)
        : await nft2GetArtifact(userContract, selectUnStakeNFTs);
    if (res) {
      toaster("success", `Get Artifact Success`);
      setSelectUnStakeNFTs([]);
      setRewardNFT([]);
      setTimeout(() => getUserNFTs(selectNFT), 500);
    }
  };

  useEffect(() => {
    intervalRef.current = setInterval(increateTimer, 1000);

    return () => clearInterval(intervalRef.current);
  }, []);

  useEffect(() => {
    getUserNFTs(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col lg:pb-5 items-center text-center background-color min-h-[100vh]">
      {loading && <Spin />}
      <div className="px-4 md:container mx-auto mt-[150px]">
        <div className="flex justify-center items-center mt-[30px]">
          <div
            className={`${
              selectNFT === 0
                ? "border-[rgb(44,52,65)] border-[3px] rounded-[50%] p-1"
                : ""
            }`}
            onClick={() => {
              updateItems(0);
            }}
          >
            <img
              src="https://i.seadn.io/gcs/files/4bbf53de963f24f668b785607a3726b6.png?w=500&auto=format"
              alt="First NFT"
              className={`border-[1px] border-white rounded-[50%] cursor-pointer w-[40px] h-[40px] transition-all duration-500 ${
                selectNFT === 0 ? "scale-100" : "scale-90"
              }`}
            />
          </div>
          <div
            className={`ml-[10px] ${
              selectNFT === 1
                ? "border-[rgb(44,52,65)] border-[3px] rounded-[50%] p-1"
                : ""
            }`}
            onClick={() => {
              updateItems(1);
            }}
          >
            <img
              src="https://i.seadn.io/gcs/files/08ffec5e847f4803b36c5371498ae4ce.jpg?w=500&auto=format"
              alt="Second NFT"
              className={`border-[1px] border-white rounded-[50%] cursor-pointer w-[40px] h-[40px] transition-all duration-500 ${
                selectNFT === 1 ? "scale-100" : "scale-90"
              }`}
            />
          </div>
        </div>
        {stakedNFTs.length === 0 && unStakeNFTs.length === 0 ? (
          <div className="mt-1 md:mt-2 lg:mt-3">
            <p className="uppercase font-BubblegumSans text-xl md:text-3xl lg:text-5xl text-white">
              YOU DON'T HAVE ANY NFTs to BUY CLICK BELOW
            </p>
            <p className="mt-1 md:mt-2 lg:mt-3">
              {selectNFT === 0 ? (
                <a
                  href="https://opensea.io/collection/digimonkz"
                  target="_blank"
                  rel="noreferrer"
                  className="text-[white] underline font-BubblegumSans text-base md:text-2xl lg:text-4xl mt-3"
                >
                  https://opensea.io/collection/digimonkz
                </a>
              ) : (
                <a
                  href="https://opensea.io/collection/digimonkzgen2"
                  target="_blank"
                  rel="noreferrer"
                  className="text-[white] underline font-BubblegumSans text-base md:text-2xl lg:text-4xl mt-3"
                >
                  https://opensea.io/collection/digimonkzgen2
                </a>
              )}
            </p>
            {selectNFT === 1 && (
              <div className=" mt-2 md:mt-3 lg:mt-4 ">
                <Link
                  className="uppercase font-BubblegumSans text-xl md:text-3xl lg:text-5xl text-white underline"
                  to="/"
                >
                  <span>OR GO TO DASHBOARD AND MINT NFT</span>
                </Link>
              </div>
            )}
          </div>
        ) : (
          <div>
            <Accordion
              open={accordionOpen === 1}
              className="border border-blue-gray-100 px-4 rounded-lg mb-2 mt-[50px]"
            >
              <AccordionHeader
                onClick={() => setAccordionOpen(1)}
                className={`border-b-0 text-white hover:text-white font-BubblegumSans tracking-[2px] ${
                  accordionOpen === 1 ? "text-[#5c9e90]" : ""
                }`}
              >
                Meditating Monkz
              </AccordionHeader>
              <AccordionBody className="text-xl text-white text-start font-BubblegumSans font-normal pt-0 tracking-[1px]">
                <div className="block md:absolute md:right-2 md:top-[2px] md:flex justify-center">
                  <button
                    className="text-xl text-white font-[400] transition-all font-BubblegumSans border-2 py-1 rounded-lg w-full md:w-[250px] tracking-[1px] uppercase hover:border-[#5c9e90] hover:text-[#5c9e90] mt-2 disabled:text-gray-700 disabled:border-gray-700 disabled:cursor-not-allowed mr-4"
                    onClick={unStakeNFTsAgain}
                    disabled={selectUnStakeNFTs.length === 0}
                  >
                    Awaken ({selectUnStakeNFTs.length} Selected)
                  </button>
                  <button
                    className="text-xl text-white font-[400] transition-all font-BubblegumSans border-2 py-1 rounded-lg w-full md:w-[200px] tracking-[1px] uppercase hover:border-[#5c9e90] hover:text-[#5c9e90] mt-2 disabled:text-gray-700 disabled:border-gray-700 disabled:cursor-not-allowed"
                    onClick={getArtifacts}
                    disabled={
                      selectUnStakeNFTs.length === 0 || rewardNFT.length !== 0
                    }
                  >
                    Get Artifacts
                  </button>
                </div>
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 lg:gap-4 2xl:grid-cols-5 3xl:grid-cols-7 4xl:grid-cols-8 5xl:grid-cols-9 mt-3">
                  {stakedNFTs.map((item) => {
                    return (
                      <Card
                        selected={selectUnStakeNFTs.includes(
                          Number(item.NFTID)
                        )}
                        imgUrl={item.NFTImage}
                        staked={true}
                        artifact={item.Artifact}
                        nextClaim={remainDate(
                          item.StakedAt,
                          new Date().getTime() / 1000 + timeDiff,
                          TEST_VERSION
                            ? selectNFT === 0
                              ? Number(item.NFTID) <= 1
                                ? NFT1_CLAIM_DATE1
                                : NFT1_CLAIM_DATE2
                              : Number(item.NFTID) <= 11
                              ? NFT2_CLAIM_DATE1
                              : NFT2_CLAIM_DATE2
                            : selectNFT === 0
                            ? Number(item.NFTID) <= 10
                              ? NFT1_CLAIM_DATE1
                              : NFT1_CLAIM_DATE2
                            : Number(item.NFTID) <= 11
                            ? NFT2_CLAIM_DATE1
                            : NFT2_CLAIM_DATE2
                        )}
                        CLAIM_DATE={
                          TEST_VERSION
                            ? selectNFT === 0
                              ? Number(item.NFTID) <= 1
                                ? NFT1_CLAIM_DATE1
                                : NFT1_CLAIM_DATE2
                              : Number(item.NFTID) <= 11
                              ? NFT2_CLAIM_DATE1
                              : NFT2_CLAIM_DATE2
                            : selectNFT === 0
                            ? Number(item.NFTID) <= 10
                              ? NFT1_CLAIM_DATE1
                              : NFT1_CLAIM_DATE2
                            : Number(item.NFTID) <= 11
                            ? NFT2_CLAIM_DATE1
                            : NFT2_CLAIM_DATE2
                        }
                        lastClaim={item.LastClaimedAt}
                        stakedAt={item.StakedAt}
                        id={Number(item.NFTID)}
                        key={item.NFTID}
                        onClaimClick={getArtifacts}
                        onStakeClick={onStakeClick}
                        onUnStakeClick={onUnStakeClick}
                      />
                    );
                  })}
                </div>
              </AccordionBody>
            </Accordion>
            <Accordion
              open={accordionOpen === 2}
              className="border border-blue-gray-100 px-4 rounded-lg mb-2"
            >
              <AccordionHeader
                onClick={() => setAccordionOpen(2)}
                className={`border-b-0 text-white hover:text-white font-BubblegumSans tracking-[2px] ${
                  accordionOpen === 2 ? "text-[#5c9e90]" : ""
                }`}
              >
                Awakened Monkz
              </AccordionHeader>
              <AccordionBody className="text-base text-white text-start font-BubblegumSans font-normal pt-0  tracking-[1px]">
                <div className="md:absolute md:right-2 md:top-[2px] flex justify-center">
                  <button
                    className="text-xl text-white font-[400] transition-all font-BubblegumSans border-2 py-1 rounded-lg w-full md:w-[250px] tracking-[1px] uppercase hover:border-[#5c9e90] hover:text-[#5c9e90] mt-2 disabled:text-gray-700 disabled:border-gray-700 disabled:cursor-not-allowed"
                    onClick={stakeNFTs}
                    disabled={selectStakeNFTs.length === 0}
                  >
                    Meditate ({selectStakeNFTs.length} Selected)
                  </button>
                </div>
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 lg:gap-4 2xl:grid-cols-5 3xl:grid-cols-7 4xl:grid-cols-8 5xl:grid-cols-9 mt-3">
                  {unStakeNFTs.map((item) => {
                    return (
                      <Card
                        selected={selectStakeNFTs.includes(Number(item.NFTID))}
                        imgUrl={item.NFTImage}
                        staked={false}
                        artifact={item.Artifact}
                        nextClaim={remainDate(
                          item.StakedAt,
                          new Date().getTime() / 1000 + timeDiff,
                          TEST_VERSION
                            ? selectNFT === 0
                              ? Number(item.NFTID) <= 1
                                ? NFT1_CLAIM_DATE1
                                : NFT1_CLAIM_DATE2
                              : Number(item.NFTID) <= 11
                              ? NFT2_CLAIM_DATE1
                              : NFT2_CLAIM_DATE2
                            : selectNFT === 0
                            ? Number(item.NFTID) <= 10
                              ? NFT1_CLAIM_DATE1
                              : NFT1_CLAIM_DATE2
                            : Number(item.NFTID) <= 11
                            ? NFT2_CLAIM_DATE1
                            : NFT2_CLAIM_DATE2
                        )}
                        CLAIM_DATE={
                          TEST_VERSION
                            ? selectNFT === 0
                              ? Number(item.NFTID) <= 1
                                ? NFT1_CLAIM_DATE1
                                : NFT1_CLAIM_DATE2
                              : Number(item.NFTID) <= 11
                              ? NFT2_CLAIM_DATE1
                              : NFT2_CLAIM_DATE2
                            : selectNFT === 0
                            ? Number(item.NFTID) <= 10
                              ? NFT1_CLAIM_DATE1
                              : NFT1_CLAIM_DATE2
                            : Number(item.NFTID) <= 11
                            ? NFT2_CLAIM_DATE1
                            : NFT2_CLAIM_DATE2
                        }
                        lastClaim={item.LastClaimedAt}
                        stakedAt={item.StakedAt}
                        id={Number(item.NFTID)}
                        key={Number(item.NFTID)}
                        onStakeClick={onStakeClick}
                        onUnStakeClick={onUnStakeClick}
                      />
                    );
                  })}
                </div>
              </AccordionBody>
            </Accordion>
          </div>
        )}
      </div>
    </div>
  );
};

export default Staking;
