import ReactDOM from "react-dom/client";
import { Buffer } from "buffer";
import { getDefaultWallets, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { configureChains, createClient, WagmiConfig } from "wagmi";
import { mainnet } from "wagmi/chains";
import { infuraProvider } from "wagmi/providers/infura";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import { ThemeProvider } from "@material-tailwind/react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Cursor from "./Components/Cursor";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ALCHEMY_KEY } from "./Common/config";

import "@rainbow-me/rainbowkit/styles.css";
import "./index.css";

window.Buffer = window.Buffer || Buffer;

const { chains, provider } = configureChains(
  [mainnet],
  [
    jsonRpcProvider({
      rpc: (chain) => ({ http: chain.rpcUrls.default.http[0] }),
    }),
    infuraProvider({ apiKey: "9886f1ae66694e72b93c9b58becfdebd" }),
    alchemyProvider({
      apiKey: ALCHEMY_KEY,
    }),
    publicProvider(),
  ]
);

const { connectors } = getDefaultWallets({
  appName: "Digimonkz",
  chains,
});

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <ThemeProvider>
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider chains={chains}>
        <Cursor />
        <App />
        <ToastContainer />
      </RainbowKitProvider>
    </WagmiConfig>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
