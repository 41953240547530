import { ethers } from "ethers";

import { toaster } from "../Components/Toast";

import {
  // CONTRACT_ABI,
  // CONTRACT_ADDRESS,
  // API_KEY,
  // ALCHEMY_BASE_URL,
  OPENSEA_NFT_INFO,
  // COLLECTION_FIRST,
  COLLECTION_SECOND,
  OPENSEA_KEY,
  UNKNOWN_IMG,
  TEST_VERSION,
  ADMIN_WALLET,
  ALCHEMY_KEY,
  // ALCHEMY_NFT_METADATA,
  // OPENSEA_URL,
  // DIGI1_ABI,
  // DIGI2_ABI,
} from "../Common/config";

const provider = new ethers.providers.WebSocketProvider(
  TEST_VERSION
    ? `wss://eth-goerli.g.alchemy.com/v2/${ALCHEMY_KEY}`
    : `wss://eth-mainnet.g.alchemy.com/v2/${ALCHEMY_KEY}`
);

export const getNFTImage = async (nftAddress: string, nftNumber: number) => {
  let returnData: any;
  const settings = {
    method: "get",
    headers: {
      accept: "application/json",
      "X-API-KEY": OPENSEA_KEY,
    },
  };
  const url = `${OPENSEA_NFT_INFO}${nftAddress}/nfts/${nftNumber}`;
  try {
    await fetch(url, settings)
      .then((resData) => resData.json())
      .then(async (json) => {
        returnData = {
          imgURL: json.nft.image_url,
        };
      });
    return returnData;
  } catch (error) {
    console.log(error);
    toaster("error", "Network Error! Please Try Again Later.");
    return {
      imgURL: UNKNOWN_IMG,
    };
  }
};

export const getNFTInfo = async (
  userContract: any,
  nftAddress: string,
  nftNumber: number,
  nftType: number
) => {
  let returnData: any;
  const settings = {
    method: "get",
    headers: {
      accept: "application/json",
      "X-API-KEY": OPENSEA_KEY,
    },
  };
  const url = `${OPENSEA_NFT_INFO}${nftAddress}/nfts/${nftNumber}`;

  try {
    await fetch(url, settings)
      .then((resData) => resData.json())
      .then(async (json) => {
        // console.log(artifact);
        // if (json.success === false) {
        //   toaster("error", "Invalid ID");
        //   returnData = {
        //     imgURL: UNKNOWN_IMG,
        //     artifact: Number(artifact.toString()),
        //   };
        //   return;
        // }

        let artifact =
          nftType === 0
            ? await getArtifactPerNFT1(userContract, nftNumber)
            : await getArtifactPerNFT2(userContract, nftNumber);
        returnData = {
          imgURL: json.nft.image_url,
          artifact: Number(artifact.toString()),
        };
      });

    // let artifact =
    //   nftType === 0
    //     ? await getArtifactPerNFT1(userContract, nftNumber)
    //     : await getArtifactPerNFT2(userContract, nftNumber);
    // returnData = {
    //   imgURL: UNKNOWN_IMG,
    //   artifact: Number(artifact.toString()),
    // };
    return returnData;
  } catch (error) {
    console.log(error);
    toaster("error", "Network Error! Please Try Again Later.");
    return {
      imgURL: UNKNOWN_IMG,
      artifact: 0,
    };
  }
};

export const getNFT = async (
  walletAddress: string,
  collectionAddress: string
) => {
  let userNFTs: any = [];
  const options = { method: "GET", headers: { accept: "application/json" } };

  const url = `https://eth-mainnet.g.alchemy.com/nft/v2/b1E50Ado6Zr1SvhuT-70sp14kcjlKtVy/getNFTs?owner=${walletAddress}&contractAddresses[]=${collectionAddress}&withMetadata=true&pageSize=150`;

  try {
    await fetch(url, options)
      .then((resData) => resData.json())
      .then((json) => {
        userNFTs = json.ownedNfts.map(function (item: any) {
          return {
            id: Number(item.id.tokenId.toString()),
            image:
              collectionAddress === "0xF9A42C357709DbB27C77109823D7F1641d86aEE9"
                ? item.metadata.image
                : collectionAddress ===
                  "0xB722920859b312d85DD814c4A9E4918D83ee76c7"
                ? item.media[0].gateway
                : item.metadata.image,
            attributes:
              collectionAddress === COLLECTION_SECOND
                ? item.metadata.attributes[0].trait_type === "Legendary"
                  ? "legend"
                  : item.metadata._c
                : [],
          };
        });
      });
    return userNFTs;
  } catch (error) {
    console.log(error);
    toaster("error", "Fetch NFT Error! Please Try Again.");
    return [];
  }
};

export const getNFT1Info = async (userContract: any, walletAddress: string) => {
  let newArray = await userContract.getGen1StakedArray(walletAddress);
  console.log(newArray);
  const stakeArray = newArray.map((item: any) => {
    return {
      NFTID: item.tokenId,
      LastClaimedAt: Number(item.lastClaimedAt.toString()),
      StakedAt: Number(item.stakedAt.toString()),
      Artifact: Number(item.artifact.toString()),
    };
  });
  return stakeArray;
};

export const getNFT2Info = async (userContract: any, walletAddress: string) => {
  let newArray = await userContract.getGen2StakedArray(walletAddress);
  const stakeArray = newArray.map((item: any) => {
    return {
      NFTID: item.tokenId,
      LastClaimedAt: Number(item.lastClaimedAt.toString()),
      StakedAt: Number(item.stakedAt.toString()),
      Artifact: Number(item.artifact.toString()),
    };
  });
  return stakeArray;
};

export const nft1Stake = async (
  userContract: any,
  // nft1Contract: any,
  // walletAddress: string,
  nftIDs: number[]
) => {
  // let nftContract1 = new ethers.Contract(
  //   COLLECTION_FIRST,
  //   DIGI1_ABI,
  //   signerUser
  // );
  let res = true;

  // const options = {
  //   method: "GET",
  //   headers: { accept: "application/json", "X-API-KEY": OPENSEA_KEY },
  // };

  // await fetch(
  //   `${OPENSEA_URL}asset_contract_address=${COLLECTION_FIRST}&token_ids=${nftID}&order_by=created_date&order_direction=desc`,
  //   options
  // )
  //   .then((response) => response.json())
  //   .then(async (response) => {
  //     if (response.orders[0]?.listing_time) {
  //       toaster("error", "NFT Listed on Opensea.");
  //       res = false;
  //       return;
  //     }

  //     console.log(response.orders[0]?.listing_time);

  // let geApproveResult = await nft1Contract.isApprovedForAll(
  //   walletAddress,
  //   CONTRACT_ADDRESS
  // );
  // console.log(geApproveResult);
  // if (!geApproveResult) {
  //   let transaction1 = await nft1Contract.setApprovalForAll(
  //     CONTRACT_ADDRESS,
  //     1
  //   );
  //   await transaction1.wait();
  // }

  console.log(nftIDs);

  await userContract
    .gen1Stake(nftIDs)
    .then(async (tx: any) => {
      await tx.wait().then(() => {
        res = true;
      });
    })
    .catch((err: any) => {
      console.log(err);
      toaster("error", "Please Stake Again.");
      res = false;
    });
  // })
  // .catch((err) => console.error(err));

  return res;
};

export const nft1UnStake = async (userContract: any, nftIDs: number[]) => {
  let res = true;
  await userContract
    .gen1Unstake(nftIDs)
    .then(async (tx: any) => {
      console.log(tx);
      await tx.wait().then(() => {
        res = true;
      });
    })
    .catch((err: any) => {
      console.log(err);
      toaster("error", "Please UnStake Again.");
      res = false;
    });
  return res;
};

export const nft2Stake = async (
  userContract: any,
  // nft2Contract: any,
  // walletAddress: string,
  nftIDs: number[]
) => {
  // let nftContract2 = new ethers.Contract(
  //   COLLECTION_SECOND,
  //   DIGI2_ABI,
  //   signerUser
  // );
  let res = true;

  // const options = {
  //   method: "GET",
  //   headers: { accept: "application/json", "X-API-KEY": OPENSEA_KEY },
  // };

  // await fetch(
  //   `${OPENSEA_URL}asset_contract_address=${COLLECTION_SECOND}&token_ids=${nftID}&order_by=created_date&order_direction=desc`,
  //   options
  // )
  //   .then((response) => response.json())
  //   .then(async (response) => {
  //     if (response.orders[0]?.listing_time) {
  //       toaster("error", "NFT Listed on Opensea.");
  //       res = false;
  //       return;
  //     }
  //     console.log(response.orders[0]?.listing_time);

  // let geApproveResult = await nft2Contract.isApprovedForAll(
  //   walletAddress,
  //   CONTRACT_ADDRESS
  // );
  // if (!geApproveResult) {
  //   let transaction2 = await nft2Contract.setApprovalForAll(
  //     CONTRACT_ADDRESS,
  //     1
  //   );
  //   await transaction2.wait();
  // }

  await userContract
    .gen2Stake(nftIDs)
    .then(async (tx: any) => {
      console.log(tx);
      await tx.wait().then(() => {
        res = true;
      });
    })
    .catch((err: any) => {
      console.log(err);
      toaster("error", "Please Stake Again.");
      res = false;
    });
  // })
  // .catch((err) => console.error(err));

  return res;
};

export const nft2UnStake = async (userContract: any, nftIDs: number[]) => {
  let res = true;
  await userContract
    .gen2Unstake(nftIDs)
    .then(async (tx: any) => {
      console.log(tx);
      await tx.wait().then(() => {
        res = true;
      });
    })
    .catch((err: any) => {
      console.log(err);
      toaster("error", "Please UnStake Again.");
      res = false;
    });
  return res;
};

export const nft1GetArtifact = async (userContract: any, nftID: number[]) => {
  let res = true;
  await userContract
    .getArtifactByGroup(nftID, [])
    .then(async (tx: any) => {
      console.log(tx);
      await tx.wait().then(() => {
        res = true;
      });
    })
    .catch((err: any) => {
      console.log(err);
      toaster("error", "Please Get Artifact Again.");
      res = false;
    });
  return res;
};

export const nft2GetArtifact = async (userContract: any, nftID: number[]) => {
  let res = true;
  await userContract
    .getArtifactByGroup([], nftID)
    .then(async (tx: any) => {
      console.log(tx);
      await tx.wait().then(() => {
        res = true;
      });
    })
    .catch((err: any) => {
      console.log(err);
      toaster("error", "Please Get Artifact Again.");
      res = false;
    });
  return res;
};

export const getArtifactPerNFT1 = async (userContract: any, nftID: number) => {
  let artifact = await userContract.artifactPerGen1Nft(nftID);
  return artifact;
};

export const getArtifactPerNFT2 = async (userContract: any, nftID: number) => {
  let artifact = await userContract.artifactPerGen2Nft(nftID);
  return artifact;
};

export const mintGenNFT = async (mintContract: any, nftCnt: number) => {
  let res = true;
  await mintContract
    .mint(nftCnt, {
      value: ethers.utils.parseEther((0.26 * nftCnt).toString()),
      gasLimit: 210000 * nftCnt,
    })
    .then(async (tx: any) => {
      console.log(tx);
      await tx.wait().then(() => {
        res = true;
      });
    })
    .catch((err: any) => {
      console.log(err);
      toaster("error", "Mint Failed! Please Try Again.");
      res = false;
    });
  return res;
};

export const getUserNFTList = async (
  userContract: any,
  ownerAddress: string
) => {
  let tokenList = await userContract.tokensOfOwner(ownerAddress);
  return tokenList;
};

export const purchaseNFTUseArtifact = async (
  stakingContract: any,
  collectionAddress: string,
  nftNumber: number,
  numArtifact: number,
  idxArray1: number[],
  artifactArray1: number[],
  idxArray2: number[],
  artifactArray2: number[]
) => {
  let res = true;
  await stakingContract
    .claimRewardWithGen(
      collectionAddress,
      nftNumber,
      numArtifact,
      idxArray1,
      artifactArray1,
      idxArray2,
      artifactArray2
    )
    .then(async (tx: any) => {
      console.log(tx);
      await tx.wait().then(() => {
        res = true;
      });
    })
    .catch((err: any) => {
      console.log(err);
      toaster("error", "Mint Failed! Please Try Again.");
      res = false;
    });
  return res;
};

export async function fetchSellNFTs(
  collectionABI: string,
  collectionAddress: string
) {
  const NFTContract = new ethers.Contract(
    collectionAddress,
    JSON.parse(collectionABI),
    provider
  );
  const ownedNFTs = await getUserNFTList(NFTContract, ADMIN_WALLET);
  return ownedNFTs;
}
