import { useState } from "react";
import { BrowserRouter } from "react-router-dom";

import Routes from "./Routes";
import Header from "./Components/Header";
import Footer from "./Components/Footer";

import { UserContext } from "./Context/UserContext";
import "./App.css";

function App() {
  const [walletAddress, setWalletAddress] = useState("");
  const [timeDiff, setTimeDiff] = useState(0);
  return (
    <UserContext.Provider
      value={{
        walletAddress,
        setWalletAddress,
        timeDiff,
        setTimeDiff,
      }}
    >
      <div className="relative overflow-hidden">
      
        <BrowserRouter>
          <Header />
          <Routes />
          <Footer />
        </BrowserRouter>
      
      </div>
    </UserContext.Provider>
  );
}

export default App;
