import { Fragment, useState, FC, ReactElement, useEffect } from "react";
import {
  Dialog,
  DialogHeader,
  DialogBody,
  Spinner,
  DialogFooter,
} from "@material-tailwind/react";
import { useContract, useSigner } from "wagmi";

import {
  getUserNFTList,
  getNFTInfo,
  purchaseNFTUseArtifact,
} from "../../Hooks/Hook";

import { INFTType, ISelectArtifactType } from "../../PropsType";

import { useUserContext } from "../../Context/UserContext";

import {
  COLLECTION_FIRST,
  COLLECTION_SECOND,
  CONTRACT_ABI,
  CONTRACT_ADDRESS,
  DIGI1_ABI,
  DIGI2_ABI,
} from "../../Common/config";

import ArtifactCard from "../ArtifactCard";
import { toaster } from "../Toast";

const ArtifactTreasureModal: FC<ArtifactTreasureModalProps> = ({
  img,
  price,
  id,
  collection,
  collectionName,
  open,
  onHandleOpen,
  onInitNFT,
}): ReactElement => {
  const { walletAddress } = useUserContext();
  const { data: signerData } = useSigner();
  const userContract = useContract({
    address: CONTRACT_ADDRESS,
    abi: CONTRACT_ABI,
    signerOrProvider: signerData,
  });
  const nft1Contract = useContract({
    address: COLLECTION_FIRST,
    abi: DIGI1_ABI,
    signerOrProvider: signerData,
  });
  const nft2Contract = useContract({
    address: COLLECTION_SECOND,
    abi: DIGI2_ABI,
    signerOrProvider: signerData,
  });

  const [loading, setLoading] = useState(false);
  const [digi1NFTs, setDigi1NFTs] = useState<INFTType[]>([]);
  const [digi2NFTs, setDigi2NFTs] = useState<INFTType[]>([]);
  const [artifactDigi1, setArtifactDigi1] = useState<ISelectArtifactType[]>([]);
  const [artifactDigi2, setArtifactDigi2] = useState<ISelectArtifactType[]>([]);

  const updateNFT1Item = (index: number, artifacts: number) => {
    setArtifactDigi1((prev: ISelectArtifactType[]) => {
      const newArray = [...prev];
      newArray[index].Artifacts = artifacts;
      return newArray;
    });
  };

  const updateNFT2Item = (index: number, artifacts: number) => {
    setArtifactDigi2((prev: ISelectArtifactType[]) => {
      const newArray = [...prev];
      newArray[index].Artifacts = artifacts;
      return newArray;
    });
  };

  const initData = async () => {
    setLoading(true);
    setDigi1NFTs([]);
    setDigi2NFTs([]);
    setArtifactDigi1([]);
    setArtifactDigi2([]);
    let digi1NFTs: INFTType[] = [];
    let digi2NFTs: INFTType[] = [];
    let digi1NFTArtifacts: ISelectArtifactType[] = [];
    let digi2NFTArtifacts: ISelectArtifactType[] = [];
    let userDigi1NFTs = await getUserNFTList(nft1Contract, walletAddress);
    let userDigi2NFTs = await getUserNFTList(nft2Contract, walletAddress);
    for (const item of userDigi1NFTs) {
      let resNFT = await getNFTInfo(
        userContract,
        COLLECTION_FIRST,
        Number(item),
        0
      );
      digi1NFTs.push({
        NFTID: item,
        NFTImage: resNFT.imgURL,
        StakedAt: 0,
        Artifact: resNFT.artifact,
        LastClaimedAt: 0,
      });
      digi1NFTArtifacts.push({
        NFTID: Number(item),
        Artifacts: 0,
      });
    }
    for (const item of userDigi2NFTs) {
      let resNFT = await getNFTInfo(
        userContract,
        COLLECTION_SECOND,
        Number(item),
        1
      );
      digi2NFTs.push({
        NFTID: item,
        NFTImage: resNFT.imgURL,
        StakedAt: 0,
        Artifact: resNFT.artifact,
        LastClaimedAt: 0,
      });
      digi2NFTArtifacts.push({
        NFTID: Number(item),
        Artifacts: 0,
      });
    }
    setDigi1NFTs(digi1NFTs);
    setArtifactDigi1(digi1NFTArtifacts);
    setDigi2NFTs(digi2NFTs);
    setArtifactDigi2(digi2NFTArtifacts);
    setLoading(false);
  };

  const onPurchaseNft = async () => {
    setLoading(true);
    let digi1NFTId = [];
    let digi2NFTId = [];
    let digi1Artifacts = [];
    let digi2Artifacts = [];
    let totalArtifacts = 0;
    for (let i = 0; i < artifactDigi1.length; i++) {
      if (artifactDigi1[i].Artifacts !== 0) {
        digi1NFTId.push(artifactDigi1[i].NFTID);
        digi1Artifacts.push(artifactDigi1[i].Artifacts);
        totalArtifacts += artifactDigi1[i].Artifacts;
      }
    }
    for (let i = 0; i < artifactDigi2.length; i++) {
      if (artifactDigi2[i].Artifacts !== 0) {
        digi2NFTId.push(artifactDigi2[i].NFTID);
        digi2Artifacts.push(artifactDigi2[i].Artifacts);
        totalArtifacts += artifactDigi2[i].Artifacts;
      }
    }
    if (totalArtifacts < price)
      toaster("info", `You must select more than ${price} Artifacts!`);
    else {
      let res = await purchaseNFTUseArtifact(
        userContract,
        collection,
        id,
        price,
        digi1NFTId,
        digi1Artifacts,
        digi2NFTId,
        digi2Artifacts
      );
      if (res) {
        toaster(
          "success",
          `${collectionName} ${id} NFT Requested. Please Wait Until Get NFT.`
        );
        onHandleOpen();
        setTimeout(() => onInitNFT(), 500);
      } else toaster("error", "Purchase Failed. Please Try Again!");
    }
    setLoading(false);
  };

  useEffect(() => {
    price !== -1 && initData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [img, price, id, collection, collectionName]);

  return (
    <Fragment>
      <Dialog
        open={open}
        handler={onHandleOpen}
        className="w-[80%] max-h-[80%] min-h-[30%] max-w-[80%] overflow-auto"
      >
        <DialogHeader className="text-center flex justify-center">
          <p className="font-BubblegumSans text-center text-3xl">
            Purchase NFT
          </p>
        </DialogHeader>
        <DialogBody className="mb-[30px]">
          {loading ? (
            <div className="w-full h-full justify-center items-center flex">
              <Spinner color="green" className="h-12 w-12" />
            </div>
          ) : (
            <div className="grid">
              <div className="block mx-auto md:flex justify-center mb-[50px]">
                <img
                  src={img}
                  alt="NFT"
                  className="w-[200px] md:w-[300px] lg:w-[400px] aspect-square object-contain rounded-full"
                />
                <div className="flex items-center justify-center md:justify-start">
                  <div className="md:ml-[40px] lg:ml-[100px] text-center md:text-start">
                    <p className="md:text-xl lg:text-2xl mt-2 font-BubblegumSans">
                      Collection Name:{" "}
                      <span className="text-[#5c9e90]">{collectionName}</span>
                    </p>
                    <p className="md:text-xl lg:text-2xl mt-2 font-BubblegumSans">
                      ID: <span className="text-[#5c9e90]">{id}</span>
                    </p>
                    <p className="md:text-xl lg:text-2xl mt-2 font-BubblegumSans">
                      Required Artifacts:{" "}
                      <span className="text-[#5c9e90]">{price}</span>
                    </p>
                    <p className="md:text-xl lg:text-2xl mt-2 font-BubblegumSans">
                      Selected Artifacts:{" "}
                      <span className="text-[#5c9e90]">
                        {artifactDigi1.reduce((accumulator, currentValue) => {
                          return accumulator + currentValue.Artifacts;
                        }, 0) +
                          artifactDigi2.reduce((accumulator, currentValue) => {
                            return accumulator + currentValue.Artifacts;
                          }, 0)}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <button
                onClick={onPurchaseNft}
                className="text-xl text-[#5c9e90] font-[400] transition-all font-BubblegumSans border-[#5c9e90] border-2 py-1 rounded-lg w-full tracking-[1px] uppercase hover:border-[#5c9e90] hover:text-[#5c9e90] mt-2 disabled:text-gray-700 disabled:border-gray-700 disabled:cursor-not-allowed mb-[50px]"
              >
                Purchase NFT
              </button>
              <div className="flex justify-center font-BubblegumSans text-lg">
                DIGIMonkz1
              </div>
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 2xl:grid-cols-5 mt-1">
                {digi1NFTs.map((item, _index) => {
                  return (
                    <ArtifactCard
                      img={item.NFTImage}
                      artifacts={item.Artifact}
                      id={Number(item.NFTID)}
                      index={_index}
                      updateDigi={updateNFT1Item}
                      key={_index + "Digi1"}
                    />
                  );
                })}
              </div>
              <div className="flex justify-center font-BubblegumSans text-lg mt-[28px]">
                DIGIMonkz2
              </div>
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 2xl:grid-cols-5 mt-1">
                {digi2NFTs.map((item, _index) => {
                  return (
                    <ArtifactCard
                      img={item.NFTImage}
                      artifacts={item.Artifact}
                      id={Number(item.NFTID)}
                      index={_index}
                      updateDigi={updateNFT2Item}
                      key={_index + "Digi2"}
                    />
                  );
                })}
              </div>
            </div>
          )}
        </DialogBody>
        <DialogFooter>
        </DialogFooter>
      </Dialog>
    </Fragment>
  );
};

type ArtifactTreasureModalProps = {
  img: string;
  price: number;
  id: number;
  collection: string;
  collectionName: string;
  open: boolean;
  onHandleOpen: () => void;
  onInitNFT: () => void;
};

export default ArtifactTreasureModal;
