import { Spinner } from "@material-tailwind/react";

const Spin = () => {
  return (
    <div className="w-[100vw] h-full min-h-[100vh] absolute flex justify-center items-center bg-[rgba(0,0,0,0.8)] z-[9]">
      <Spinner color="amber" className="h-12 w-12" />
    </div>
  );
};

export default Spin;
