import { FC, ReactElement, useState } from "react";

const ArtifactCard: FC<CardProps> = ({
  img,
  artifacts,
  id,
  index,
  updateDigi,
}): ReactElement => {
  const [sliderValue, setSliderValue] = useState(0);

  return (
    <div className="w-full h-full">
      <div className="w-full h-full relative aspect-square">
        <img
          src={img}
          alt="NFT"
          className="w-full h-full rounded-lg object-contain"
        />
        <div className="flex justify-between w-full absolute px-2 py-1 bottom-0">
          <p className="font-BubblegumSans text-[white] text-[14px]">
            {artifacts} Artifacts
          </p>
          <p className="font-BubblegumSans text-[white] text-[14px]">#{id}</p>
        </div>
      </div>
      <div className="w-full flex">
        <input
          id="customRange1"
          className="w-full"
          type="range"
          min={0}
          max={artifacts}
          step={1}
          defaultValue={sliderValue}
          onChange={(e) => {
            setSliderValue(Number(e.target.value));
            updateDigi(index, Number(e.target.value));
          }}
        />
        <p className="flex justify-center ml-[10px] font-BubblegumSans">
          {sliderValue}
        </p>
      </div>
    </div>
  );
};

type CardProps = {
  img: string;
  artifacts: number;
  id: number;
  index: number;
  updateDigi: (index: number, artifact: number) => void;
};

export default ArtifactCard;
